import React, { useCallback, useEffect, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import CustomSearchInput from "../../../components/common/CustomSearchInput";
import DataTable from "react-data-table-component";
import NoRecords from "../../../components/common/NoRecords";
import { withTranslation } from "react-i18next";

import Loader from "../../../components/common/Loader";
import CustomTooltipWrapper from "../../../components/common/CustomTooltipWrapper";
import CustomColumnHeader from "../../../components/common/CustomColumnHeader";
import { createTask, deleteTask, getTaskLists, updateTask } from "../../../store/tasks/action";
import {
  checkSelectAllSelected,
  generateContrastColor,
  getLabelValueArray,
  getTypeFromValue,
  getValuesFromArray,
} from "../../../helpers/utils";
import CustomAddButton from "../../../components/common/CustomAddButton";
import AddTaskModal from "../../../components/pages/home/AddTaskModal";
import DeleteModal from "../../../components/common/DeleteModal";
import { PRIORITY_OPTIONS, SELECT_ALL_OPTION, STATUS_OPTIONS } from "../../../helpers/constants/selectOptions";
import CustomMultiSelect from "../../../components/common/CustomMultiSelect";
import CustomTagChip from "../../../components/common/CustomTagChip";

const INITIAL_PAYLOAD = {
  search: "",
  page: 1,
  limit: 10,
  filter: {
    id__icontains: "",
    description__icontains: "",
  },
};

const Tasks = (props) => {
  const dispatch = useDispatch();
  const { data: taskData, total: totalTasks } = useSelector((state) => state.Tasks);
  const userData = getLabelValueArray(
    useSelector((state) => state?.GeneralUserSettings?.data),
    "id",
    "name"
  )?.sort((a, b) => a?.label?.localeCompare(b?.label));

  const [isGetTasksLoader, setIsGetTasksLoader] = useState(false);
  const [isAddTasksModalOpen, setIsAddTasksModalOpen] = useState(false);
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [taskDeleteLoader, setTaskDeleteLoader] = useState(false);
  const [TaskSaveLoader, setTaskSaveLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [taskPayload, setTaskPayload] = useState(INITIAL_PAYLOAD);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedCreateBy, setSelectedCreateBy] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState([]);

  // for sorting
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");

  // setting title of the page
  document.title = `Veritas | ${props.t("sidebarMenu.tasks")}`;

  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setTaskPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  const handleGetTaskList = useCallback(
    (taskPayload) => {
      setIsGetTasksLoader(true);
      dispatch(getTaskLists(taskPayload))
        .then((res) => {
          if (res) {
            //
          } else {
            //
          }
          setIsGetTasksLoader(false);
        })
        .catch((error) => {
          setIsGetTasksLoader(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    handleGetTaskList(taskPayload);
  }, [handleGetTaskList, taskPayload]);

  const handleSaveTask = (values, validation) => {
    setTaskSaveLoader(true);

    if (selectedRow && selectedRow.id) {
      // dispatch for editing page
      dispatch(updateTask({ id: selectedRow.id, values: values }))
        .then((res) => {
          if (res) {
            //
            handleGetTaskList(taskPayload);
            validation.resetForm();
            setIsAddTasksModalOpen(false);
          } else {
            //
          }
          setTaskSaveLoader(false);
        })
        .catch((error) => {
          setIsAddTasksModalOpen(false);
          setTaskSaveLoader(false);
        });
    } else {
      dispatch(createTask(validation?.values))
        .then((res) => {
          if (res) {
            //
            handleGetTaskList(taskPayload);
            validation.resetForm();
            setIsAddTasksModalOpen(false);
          } else {
            //
          }
          setTaskSaveLoader(false);
        })
        .catch((error) => {
          setIsAddTasksModalOpen(false);
          setTaskSaveLoader(false);
        });
    }
  };

  // handle page change
  const handlePageChange = (value) => {
    setTaskPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setTaskPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  const taskEditHandler = (row) => {
    setIsAddTasksModalOpen(true);
    setSelectedRow(row);
  };

  const taskDeleteHandler = (row) => {
    setSelectedRow(row);
    setIsDeleteTaskModalOpen(true);
  };

  const handleConfirmBtn = () => {
    setTaskDeleteLoader(true);

    // to delete the task
    dispatch(deleteTask(selectedRow.id))
      .then((res) => {
        if (res) {
          //
          handleGetTaskList(taskPayload);
        } else {
          //
        }
        setTaskDeleteLoader(false);
        setIsDeleteTaskModalOpen(false);
      })
      .catch((error) => {
        setTaskDeleteLoader(false);
        setIsDeleteTaskModalOpen(false);
      });
  };

  const handleCloseBtn = () => {
    setIsDeleteTaskModalOpen(false);
  };

  const handleTaskModalOpen = () => {
    setSelectedRow(null);
    setIsAddTasksModalOpen(true);
  };

  const handleTaskModalClose = () => {
    setIsAddTasksModalOpen(false);
  };

  // handle product or service type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setTaskPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setTaskPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setTaskPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  const columns = [
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.id")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="id__icontains"
            className="column-search-input"
            payload={taskPayload}
            setPayload={setTaskPayload}
          />
        </div>
      ),
      selector: (row) => row?.id,
      width: "142px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.description")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="description__icontains"
            className="column-search-input"
            payload={taskPayload}
            setPayload={setTaskPayload}
          />
        </div>
      ),
      selector: (row) => row?.description,
      width: "285px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.status")}`}
            sortable
            sortableKey="status"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...STATUS_OPTIONS]}
            selectedOptions={selectedStatus}
            handleOnSelection={(values) => {
              setSelectedStatus(values);
              handleDropdownFilter(values, "status__in", setSelectedStatus);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <>
          <CustomTagChip
            title={getTypeFromValue(STATUS_OPTIONS, row?.status)?.label}
            bgColor={row?.status === "pending" ? "#FF5F33" : "#37B71D"}
            textColor={generateContrastColor(row?.status === "pending" ? "#FF5F33" : "#37B71D")}
          />
        </>
      ),
      width: "214px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.priority")}`}
            sortable
            sortableKey="priority"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...PRIORITY_OPTIONS]}
            selectedOptions={selectedPriority}
            handleOnSelection={(values) => {
              setSelectedPriority(values);
              handleDropdownFilter(values, "priority__in", setSelectedPriority);
            }}
          />
        </div>
      ),
      selector: (row) => (
        <>
          <CustomTagChip
            title={getTypeFromValue(PRIORITY_OPTIONS, row?.priority)?.label}
            bgColor={row?.priority === "low" ? "#F7CB73" : row?.priority === "normal" ? "#57BDD9" : "#CC0202"}
            textColor={generateContrastColor(
              row?.priority === "low" ? "#F7CB73" : row?.priority === "normal" ? "#57BDD9" : "#CC0202"
            )}
          />
        </>
      ),
      width: "214px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("home.tasks.assignTo")}`}
            sortable
            sortableKey="user__name"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...userData]}
            selectedOptions={selectedUser}
            handleOnSelection={(values) => {
              setSelectedUser(values);
              handleDropdownFilter(values, "user__in", setSelectedUser);
            }}
          />
        </div>
      ),
      selector: (row) => row?.user?.name || "-",
      width: "214px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("home.tasks.assignBy")}`}
            sortable
            sortableKey="created_by__name"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomMultiSelect
            placeholder={props.t("common.select")}
            options={[SELECT_ALL_OPTION, ...userData]}
            selectedOptions={selectedCreateBy}
            handleOnSelection={(values) => {
              setSelectedCreateBy(values);
              handleDropdownFilter(values, "created_by__in", setSelectedCreateBy);
            }}
          />
          {/* <div className="table-column-title mb-2">
            {props.t("home.tasks.assignBy")}
          </div> */}
        </div>
      ),
      selector: (row) => row?.created_by?.name || "-",
      width: "214px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.actions")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => taskEditHandler(row)}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-edit`} tooltipBody={props.t("common.edit")} />
            <i className="ri-pencil-fill fs-18" id={`tooltip-${row?.id}-edit`} />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => taskDeleteHandler(row)}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      width: "142px",
      center: true,
      wrap: true,
    },
  ];

  return (
    <>
      <div className="page-content layout-main-container">
        <div className="container-header">
          <CustomSearchInput
            className="custom-search-input"
            payload={taskPayload}
            setPayload={setTaskPayload}
            disabled={isGetTasksLoader}
          />
          <div className="contain-header-right d-flex flex-row gap-2">
            <CustomAddButton
              color={"success"}
              onClick={handleTaskModalOpen}
              btnTitle={`${props.t("common.add")} ${props.t("home.tasks.task")}`}
            />
          </div>
        </div>
        <Card className="container-body">
          {isGetTasksLoader ? <Loader /> : null}
          <CardHeader className="table-title p-2">{props.t("sidebarMenu.tasks")}</CardHeader>
          <DataTable
            fixedHeader
            className="data-table"
            persistTableHead
            // selectableRows
            columns={columns}
            data={[...taskData]}
            pagination
            paginationServer
            paginationTotalRows={totalTasks}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
          />
        </Card>
      </div>

      {/* to add /edit tasks */}
      <AddTaskModal
        isModalOpen={isAddTasksModalOpen}
        task={selectedRow}
        onCancel={handleTaskModalClose}
        onSave={handleSaveTask}
        loader={TaskSaveLoader}
      />

      {/* delete modal for  tasks*/}
      <DeleteModal
        title={`${props.t("common.delete")} ${props.t("home.tasks.task")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("home.tasks.task"),
        })}
        actionBtnTitle={`${props.t("common.delete")}`}
        loader={taskDeleteLoader}
        isModalOpen={isDeleteTaskModalOpen}
        onConfirmBtnHandler={handleConfirmBtn}
        onCloseBtnHandler={handleCloseBtn}
      />
    </>
  );
};

export default withTranslation()(Tasks);
