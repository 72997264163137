import i18next from "i18next";

// document type
export const MOVEMENT_TYPES = [
  { value: "debit", label: i18next.t("common.debit") },
  { value: "credit", label: i18next.t("common.credit") },
  { value: "neutral", label: i18next.t("common.neutral") },
  { value: "debit_credit", label: i18next.t("common.debit/credit") },
];

export const PRINTED_TYPES = [
  { value: "invoice", label: i18next.t("common.invoice") },
  { value: "receipt", label: i18next.t("common.receipt") },
];

export const ACTIVE_SELECTION = [
  { value: true, label: i18next.t("common.yes") },
  { value: false, label: i18next.t("common.no") },
];

export const TAG_TYPES = [
  {
    value: "regularized",
    iconClasses: "ri-check-double-fill text-success",
    text: i18next.t("common.settled"),
  },
  {
    value: "issued",
    iconClasses: "ri-check-fill text-success",
    text: i18next.t("common.issued"),
  },
  {
    value: "canceled",
    iconClasses: "ri-close-fill text-danger",
    text: i18next.t("common.canceled"),
  },
  {
    value: "not-reconciled",
    iconClasses: "ri-alert-fill text-warning",
    text: i18next.t("common.notReconciled"),
  },
];

export const PRODUCT_UNIT_TYPES = [
  { value: "%", label: "%" },
  { value: "€", label: "€" },
];

export const PRODUCT_SERVICE_TYPES = [
  { value: "product", label: i18next.t("common.product") },
  { value: "service", label: i18next.t("common.service") },
  { value: "fees_taxes", label: i18next.t("common.fees&taxes") },
  { value: "other", label: i18next.t("common.other") },
];

export const SELECT_ALL_OPTION = {
  value: "*",
  label: i18next.t("common.selectAll"),
  text: i18next.t("common.selectAll"),
};

// none option
export const SELECT_NONE_OPTION = {
  value: "none",
  label: i18next.t("common.none"),
};

// option type for the tags of the pre-contracts items.
export const PRECONTRACT_ITEMS_TAG_OPTIONS = [
  { value: true, label: i18next.t("common.described") },
  { value: false, label: i18next.t("common.notDescribed") },
];

// option types for the yes/no option global
export const YES_NO_OPTIONS = [
  { value: true, label: i18next.t("common.yes") },
  { value: false, label: i18next.t("common.no") },
];

// option types for the sent in pre-contracts items.
export const PRECONTRACT_ITEMS_SENT_OPTIONS = [
  { value: true, label: i18next.t("common.yes") },
  { value: false, label: i18next.t("common.no") },
];
// option for the customer.
export const POST_CARD_OPTION = [
  {
    value: "antiques",
    label: i18next.t("customers.postcard-cataloguesOption.antiques"),
  },
  {
    value: "amc",
    label: i18next.t("customers.postcard-cataloguesOption.amc"),
  },
  {
    value: "asian_art",
    label: `${i18next.t("customers.postcard-cataloguesOption.asian")} ${i18next.t("common.art")}`,
  },
  {
    value: "classic_cars",
    label: i18next.t("customers.postcard-cataloguesOption.classicCars"),
  },
  {
    value: "jewelry",
    label: i18next.t("customers.tagOption.jewelry"),
  },
  {
    value: "spoken",
    label: i18next.t("settings.general.language.spoken"),
  },
  {
    value: "watches",
    label: i18next.t("customers.tagOption.watches"),
  },
  {
    value: "pens",
    label: i18next.t("customers.postcard-cataloguesOption.pens"),
  },
  {
    value: "design",
    label: i18next.t("common.design"),
  },
  {
    value: "books_manuscripts",
    label: i18next.t("customers.postcard-cataloguesOption.books&manuscripts"),
  },
  {
    value: "numismatics",
    label: i18next.t("customers.postcard-cataloguesOption.numismatics"),
  },
  {
    value: "wines_spirits",
    label: i18next.t("customers.postcard-cataloguesOption.wines_spirits"),
  },
];

// backup if catalouge changes
// export const CATALOGUES_OPTION = POST_CARD_OPTION;

export const TAG_OPTION = [
  { value: "vip", label: i18next.t("customers.tagOption.vip") },
  {
    value: "vip_antiques",
    label: `${i18next.t("customers.tagOption.vip")} ${i18next.t("customers.postcard-cataloguesOption.antiques")}`,
  },
  {
    value: "vip_amc",
    label: `${i18next.t("customers.tagOption.vip")} ${i18next.t("customers.postcard-cataloguesOption.amc")}`,
  },
  {
    value: "vip_watches",
    label: `${i18next.t("customers.tagOption.vip")} ${i18next.t("customers.tagOption.watches")}`,
  },
  {
    value: "vip_jewelry",
    label: `${i18next.t("customers.tagOption.vip")} ${i18next.t("customers.tagOption.jewelry")}`,
  },
  {
    value: "vip_silver",
    label: `${i18next.t("customers.tagOption.vip")} ${i18next.t("customers.tagOption.silver")}`,
  },
  {
    value: "new_business",
    label: `${i18next.t("common.new")} ${i18next.t("customers.tagOption.business")}`,
  },
  { value: "late_payer", label: i18next.t("customers.tagOption.latePayer") },
  { value: "blacklist", label: i18next.t("customers.tagOption.blackList") },
];

export const ADDRESS_OPTION = [
  { value: "internationals", label: i18next.t("common.internationals") },
  { value: "portugal", label: i18next.t("common.portugal") },
];

export const CUS_TITLE_OPTION = [
  { value: "-", label: "-" },
  { value: "arch", label: i18next.t("common.arch") },
  { value: "commander", label: i18next.t("common.commander") },
  { value: "exmo sr", label: i18next.t("common.exmSr") },
  { value: "exma sra", label: i18next.t("common.exmSra") },
  { value: "dr", label: i18next.t("common.dr") },
  { value: "dra", label: i18next.t("common.dra") },
  { value: "prof", label: i18next.t("common.prof") },
  { value: "profa", label: i18next.t("common.profa") },
  { value: "eng", label: i18next.t("common.eng") },
  { value: "enga", label: i18next.t("common.enga") },
  { value: "mr", label: i18next.t("common.mr") },
  { value: "mrs", label: i18next.t("common.mrs") },
  { value: "miss", label: i18next.t("common.miss") },
  { value: "m", label: i18next.t("common.m_dot") },
  { value: "mme", label: i18next.t("common.mme") },
  { value: "herr", label: i18next.t("common.herr") },
  { value: "frau", label: i18next.t("common.frau") },
];

export const LANGUAGE_OPTIONS = [
  { value: "portuguese", label: i18next.t("common.portuguese") },
  { value: "english", label: i18next.t("common.english") },
];

// Activity area options
export const ACTIVITY_AREA_OPTION = [
  {
    value: "agriculture_animal_production_hunting_forestry_and_fishing",
    label: i18next.t("customers.activityAreaOption.agriculture_animal_production_hunting_forestry_and_fishing"),
  },
  {
    value: "extractive_industries",
    label: i18next.t("customers.activityAreaOption.extractive_industries"),
  },
  {
    value: "manufacturing_industries",
    label: i18next.t("customers.activityAreaOption.manufacturing_industries"),
  },
  {
    value: "electricity_gas_steam_hot_and_cold_water_and_cold_air",
    label: i18next.t("customers.activityAreaOption.electricity_gas_steam_hot_and_cold_water_and_cold_air"),
  },
  {
    value: "water_catchment_treatment_and_distribution_sanitation_waste_management_and_depollution",
    label: i18next.t(
      "customers.activityAreaOption.water_catchment_treatment_and_distribution_sanitation_waste_management_and_depollution"
    ),
  },
  {
    value: "construction",
    label: i18next.t("customers.activityAreaOption.construction"),
  },
  {
    value: "wholesale_and_retail_trade_repair_of_motor_vehicles_and_motorcycles",
    label: i18next.t(
      "customers.activityAreaOption.wholesale_and_retail_trade_repair_of_motor_vehicles_and_motorcycles"
    ),
  },
  {
    value: "transportation_warehousing",
    label: i18next.t("customers.activityAreaOption.transportation_warehousing"),
  },
  {
    value: "accommodation_catering_and_similar",
    label: i18next.t("customers.activityAreaOption.accommodation_catering_and_similar"),
  },
  {
    value: "information_communication_activities",
    label: i18next.t("customers.activityAreaOption.information_communication_activities"),
  },
  {
    value: "financial_insurance_activities",
    label: i18next.t("customers.activityAreaOption.financial_insurance_activities"),
  },
  {
    value: "real_estate_activities",
    label: i18next.t("customers.activityAreaOption.real_estate_activities"),
  },
  {
    value: "consulting_scientific_technical_and_similar_activities",
    label: i18next.t("customers.activityAreaOption.consulting_scientific_technical_and_similar_activities"),
  },
  {
    value: "administrative_and_support_services_activities",
    label: i18next.t("customers.activityAreaOption.administrative_and_support_services_activities"),
  },
  {
    value: "education",
    label: i18next.t("customers.activityAreaOption.education"),
  },
  {
    value: "human_health_and_social_support_activities",
    label: i18next.t("customers.activityAreaOption.human_health_and_social_support_activities"),
  },
  {
    value: "artistic_entertainment_sports_and_recreational_activities",
    label: i18next.t("customers.activityAreaOption.artistic_entertainment_sports_and_recreational_activities"),
  },
  {
    value: "other_activities_and_services",
    label: i18next.t("customers.activityAreaOption.other_activities_and_services"),
  },
];

// priority options
export const STATUS_OPTIONS = [
  { value: "pending", label: i18next.t("common.pending") },
  { value: "solved", label: i18next.t("common.solved") },
];

// priority options
export const PRIORITY_OPTIONS = [
  { value: "urgent", label: i18next.t("common.urgent") },
  { value: "normal", label: i18next.t("common.normal") },
  { value: "low", label: i18next.t("common.low") },
];

// contract items status options
export const CONTRACT_STATUS_OPTIONS = [
  {
    value: "notDescribed",
    label: i18next.t("common.notDescribed"),
    color: "#EB144C",
  },
  {
    value: "described",
    label: i18next.t("common.described"),
    color: "#8ed1fc",
  },
  {
    value: "highlighted",
    label: i18next.t("common.highlighted"),
    color: "#FF8900",
  },
  {
    value: "photographed",
    label: i18next.t("common.photographed"),
    color: "#fae232",
  },
  {
    value: "translated",
    label: i18next.t("common.translated"),
    color: "#61d836",
  },
];

// new persona type
export const NEW_PERSONA_TYPE = [
  { value: "personal", label: i18next.t("common.personal") },
  { value: "company", label: i18next.t("common.company") },
];

// type of bidding options
export const TYPE_OF_BID_OPTIONS = [
  {
    value: "absentee",
    label: i18next.t("common.absenteeBid"),
  },
  {
    value: "telephone",
    label: i18next.t("common.telephoneBid"),
  },
];

// types of bidding origin
export const BIDDING_ORIGIN_OPTIONS = [
  { value: "email", label: i18next.t("common.email") },
  { value: "externals", label: i18next.t("common.externals") },
  { value: "face_to_face", label: i18next.t("common.faceToFace") },
  { value: "platforms", label: i18next.t("common.platforms") },
  { value: "telephone_contact", label: i18next.t("common.telephoneContact") },
  { value: "website", label: i18next.t("common.website") },
];
// auction lot items tag options
export const LOT_TAG_OPTIONS = [
  {
    value: "Sold",
    label: i18next.t("common.sold"),
    color: "#fffc66",
  },
  {
    value: "Unpaid",
    label: i18next.t("common.unpaid"),
    color: "#d9512c",
  },
  {
    value: "Paid",
    label: i18next.t("common.paid"),
    color: "#017100",
  },
  {
    value: "Awaiting seller payment",
    label: `${i18next.t("common.awaiting")} ${i18next.t("auctions.lots.seller")} ${i18next.t("common.payment")}`,
    color: "#f29339",
  },
  {
    value: "Paid to seller",
    label: i18next.t("common.paidToSeller"),
    color: "#014000",
  },
  {
    value: "Delivered",
    label: i18next.t("common.delivered"),
    color: "#1db100",
  },
  {
    value: "Lot reserved",
    label: `${i18next.t("common.lot")} ${i18next.t("common.reserved")}`,
    color: "#8ed1fc",
  },
  {
    value: "Lot available",
    label: `${i18next.t("common.lot")} ${i18next.t("common.available")}`,
    color: "#61d836",
  },
  {
    value: "Lot returned",
    label: `${i18next.t("common.lot")} ${i18next.t("common.returned")}`,
    color: "#EB144C",
  },
];

export const TAG_BUREAUCRACY_OPTIONS = [
  {
    value: "asae_map",
    label: `${i18next.t("common.ASAE")}`,
    color: "#017100",
  },
  {
    value: "cites",
    label: `${i18next.t("common.CITES")}`,
    color: "#EB144C",
  },
  {
    value: "general_psp_map",
    label: `${i18next.t("common.generalPJ")}`,
    color: "#1db100",
  },
  {
    value: "jewelry_psp_map",
    label: `${i18next.t("common.jewelryPJ")}`,
    color: "#fffc66",
  },
];

export const AUCTION_STATUS_OPTIONS = [
  {
    value: "In preparation",
    label: `${i18next.t("common.inPreparation")}`,
    color: "#fffc66",
  },
  {
    value: "In progress",
    label: `${i18next.t("common.inProgress")}`,
    color: "#EB144C",
  },
  {
    value: "Finished",
    label: `${i18next.t("common.finished")}`,
    color: "#017100",
  },
];

// bids status
export const BIDS_STATUSES = [
  {
    label: i18next.t("common.approved"),
    color: "#37B71D",
    value: "approved",
  },
  {
    label: i18next.t("common.pending"),
    color: "#FF5F33",
    value: "pending",
  },
  {
    label: i18next.t("common.rejected"),
    color: "#CC0202",
    value: "rejected",
  },
];

// metal options
export const METAL_OPTIONS = [
  {
    label: i18next.t("common.none"),
    value: "none",
  },
  {
    label: i18next.t("common.gold"),
    value: "gold",
  },
  {
    label: i18next.t("common.platinum"),
    value: "platinum",
  },
  {
    label: i18next.t("common.silver"),
    value: "silver",
  },
];

// type of product for the document lines
export const TYPE_OF_PRODUCTS = [
  { label: i18next.t("common.product"), value: "product" },
  { label: i18next.t("common.service"), value: "service" },
  { label: i18next.t("common.lot"), value: "lot" },
  { label: i18next.t("common.expense"), value: "expense" },
  { label: i18next.t("common.debitNote"), value: "debit_note" },
];
// type from where to select lots
export const FROM_TYPE = [
  { label: i18next.t("common.auction"), value: "auction" },
  { label: i18next.t("common.itemsListAvailableLots"), value: "contract" },
];

// discount unit types
export const DISCOUNT_UNIT_TYPES = [
  { label: i18next.t("%"), value: "%" },
  { label: i18next.t("€"), value: "€" },
];
// metal options
export const AUCTION_TYPE_OPTION = [
  {
    label: i18next.t("common.live"),
    value: "live",
  },
  {
    label: i18next.t("common.online"),
    value: "online",
  },
];

// tag types for the documents.
export const DOCUMENT_TAG_TYPES = [
  {
    value: "is_issued",
    iconClasses: "ri-check-fill text-success",
    text: i18next.t("common.issued"),
  },
  {
    value: "is_settled",
    iconClasses: "ri-check-double-fill text-success",
    text: i18next.t("common.settled"),
  },
  {
    value: "is_cancel",
    iconClasses: "ri-close-fill text-danger",
    text: i18next.t("common.canceled"),
  },
  {
    value: "is_not_reconciled",
    iconClasses: "ri-alert-fill text-warning",
    text: i18next.t("common.notReconciled"),
  },
];

// FAQ types
export const TYPE_OF_FAQS = [
  {
    value: "general",
    label: i18next.t("common.general"),
  },
  {
    value: "buying",
    label: i18next.t("common.buying"),
  },
  {
    value: "selling",
    label: i18next.t("common.selling"),
  },
];

// FAQ language Types
export const FAQ_LANGUAGE_TYPES = [
  {
    value: "en",
    label: i18next.t("common.english"),
  },
  {
    value: "pt",
    label: i18next.t("common.portuguese"),
  },
];

// team positions
export const TEAM_POSITIONS = [
  { value: "ceo", label: i18next.t("common.ceo") },
  { value: "head_director", label: i18next.t("common.headDirector") },
  { value: "specialist", label: i18next.t("common.specialist") },
  {
    value: "appraisals_assistant",
    label: i18next.t("common.appraisalsAssistant"),
  },
  {
    value: "silver_jewellery_expert",
    label: i18next.t("common.silver&jewelleryExpert"),
  },
  {
    value: "watches_expert",
    label: i18next.t("common.watchesExpert"),
  },
  {
    value: "consultant",
    label: i18next.t("common.consultant"),
  },
  {
    value: "front_office_manager",
    label: i18next.t("common.frontOfficeManager"),
  },
  {
    value: "front_officer",
    label: i18next.t("common.frontOfficer"),
  },
  {
    value: "executive_assistant",
    label: i18next.t("common.executiveAssistant"),
  },
  {
    value: "marketing_communications",
    label: i18next.t("common.marketing&communications"),
  },
  {
    value: "project_management",
    label: i18next.t("common.projectManagement"),
  },
  {
    value: "graphic_design",
    label: i18next.t("common.graphicDesign"),
  },
  {
    value: "photography",
    label: i18next.t("common.photography"),
  },
  {
    value: "logistics",
    label: i18next.t("common.logistics"),
  },
];

// category wise options
export const CATEGORY_WISE_OPTIONS = [
  { label: i18next.t("common.items"), value: "items" },
  { label: i18next.t("common.saleValue"), value: "sales" },
  { label: i18next.t("common.buying"), value: "buying" },
];

// customer type
export const CUSTOMER_TYPE_OPTIONS = [
  { label: i18next.t("common.buyers"), value: "buyers" },
  { label: i18next.t("common.sellers"), value: "sellers" },
];

// graph (top 50 buyer/seller) by
export const CUSTOMER_CATEGORIES_OPTIONS = [
  { label: i18next.t("common.value"), value: "value" },
  { label: i18next.t("common.volume"), value: "volume" },
];

// title type option
export const TITLE_TYPE_OPTIONS = [
  { label: i18next.t("common.general"), value: "general" },
  { label: i18next.t("common.communication"), value: "communication" },
];

// tax exemption codes
export const TAX_EXEMPTION_OPTIONS = [
  { label: i18next.t("common.mo5Label"), value: "M05" },
  { label: i18next.t("common.m16Label"), value: "M16" },
];

//credit note codes
export const CREDIT_NOTE_OPTIONS = [
  { label: i18next.t("common.previousNotExact"), value: "78º, nº3" },
  {
    label: i18next.t("common.disountsCancellationsReturns"),
    value: "78º, nº4",
  },
  {
    label: i18next.t("common.CalculationErrorsPreviousInvoice"),
    value: "78º, nº6",
  },
];
