import { FormikProvider, useFormik } from "formik";
import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import ActionButton from "../../../components/common/ActionButton";
import CustomBreadcrumb from "../../../components/common/CustomBreadcrumb";
import CustomButton from "../../../components/common/CustomButton";
import CustomDropDown from "../../../components/common/CustomDropDown";
import Loader from "../../../components/common/Loader";
import NumberInput from "../../../components/common/NumberInput";
import ExpensesForm from "../../../components/pages/contracts/ExpensesForm";
import AddArtistModal from "../../../components/pages/settings/AddArtistModal";
import AddCategoryModal from "../../../components/pages/settings/AddCategoryModal";
import AddPeriodModal from "../../../components/pages/settings/AddPeriodModal";
import { Option } from "../../../components/pages/settings/AddUserModal";
import { DEBOUNCE_TIME, DROPDOWN_DEFAULT_LIMIT, TEXTAREA_DEFAULT_ROWS } from "../../../helpers/constants";
import { METAL_OPTIONS, SELECT_ALL_OPTION } from "../../../helpers/constants/selectOptions";
import {
  checkSelectAllSelected,
  generateExpensesData,
  getColumnDetail,
  getLabelValueArray,
  getOnlyCustomerName,
  getTypeFromValue,
  getVATOptioins,
  getValuesFromArray,
  onKeyPressForm,
  scrollToFirstFormikError,
} from "../../../helpers/utils";
import { addArtist, getArtists, updateArtist } from "../../../store/artists/actions";
import { addCategory, getCategories, updateCategory } from "../../../store/categories/actions";
import { addPeriod, getPeriods, updatePeriod } from "../../../store/periods/actions";
import { RESET_PRECONTRACT_ITEM } from "../../../store/pre-contracts/actionTypes";
import {
  addPrecontractItem,
  getPrecontractById,
  getPrecontractItemById,
  updatePrecontractItem,
} from "../../../store/pre-contracts/actions";
import { getVATs } from "../../../store/vat/actions";

const PreContractItemForm = (props) => {
  const { id, itemId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const itemIdToCopy = location?.state?.itemIdToCopy;
  const latestPosition = location?.state?.lastPosition || 0;
  const [appraisalPhotos, setAppraisalPhotos] = useState([]);
  const [defaultVAT, setDefaultVAT] = useState(null);
  const categories = useSelector((state) => state?.Categories?.data);
  const categoryOptions = useSelector((state) => state?.Categories?.data);
  const CATEGORIES_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Categories?.data),
    "id",
    i18next.resolvedLanguage === "en" ? "category_name_en" : "category_name_pt"
  );
  const ARTISTS_OPTIONS = useSelector((state) => state?.Artists?.data);
  const PERIODS_OPTIONS = useSelector((state) => state?.Periods?.data);
  const vats = useSelector((state) => state?.VAT.data);
  const VAT_OPTIONS = getVATOptioins(
    useSelector((state) => state?.VAT.data),
    "id",
    "code",
    "rate",
    " - ",
    "%"
  );
  const precontractData = useSelector((state) => state?.PreContracts?.precontract);
  const precontractItem = useSelector((state) => state?.PreContracts?.precontractItem);
  const [itemInfoLoader, setItemInfoLoader] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isArtistModalOpen, setIsArtistModalOpen] = useState(false);
  const [isPeriodModalOpen, setIsPeriodModalOpen] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [saveLoader, setSaveLoader] = useState(false);
  const [modalSaveLoader, setModalSaveLoader] = useState(false);
  const [categoriesPayload, setCategoriesPayload] = useState({
    page: 1,
    sort: getColumnDetail("category_name_en", "category_name_pt"),
    filter:
      i18next.resolvedLanguage === "pt" ? { category_name_pt__icontains: "" } : { category_name_en__icontains: "" },
  });
  const [artistSearchLoader, setArtistSearchLoader] = useState(false);
  const [artistsPayload, setArtistsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: "description",
    filter: {
      description__icontains: "",
    },
  });
  const [periodSearchLoader, setPeriodSearchLoader] = useState(false);
  const [periodsPayload, setPeriodsPayload] = useState({
    page: 1,
    limit: DROPDOWN_DEFAULT_LIMIT,
    sort: getColumnDetail("title_en", "title_pt"),
    filter: i18next.resolvedLanguage === "pt" ? { title_pt__icontains: "" } : { title_en__icontains: "" },
  });
  const [vatsPayload, setVatsPayload] = useState({
    page: 1,
    sort: "code",
    filter: {
      code__icontains: "",
    },
  });

  const handleGetArtists = useCallback(
    (artistsPayload) => {
      setArtistSearchLoader(true);
      dispatch(getArtists(artistsPayload)).then(() => {
        setArtistSearchLoader(false);
      });
    },
    [dispatch]
  );

  // handle artist search
  const handleArtistSearchDebounce = (value) => {
    setArtistsPayload((prevState) => ({
      ...prevState,
      filter: { ...prevState?.filter, description__icontains: value },
    }));
  };
  // debounce for artist search
  const artitstSearchDebounce = useMemo(() => {
    return debounce(handleArtistSearchDebounce, DEBOUNCE_TIME);
  }, []);

  // handle period search
  const handlePeriodSearchDebounce = (value) => {
    setPeriodsPayload((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        [getColumnDetail("title_en__icontains", "title_pt__icontains")]: value,
      },
    }));
  };
  // debounce for period search
  const periodSearchDebounce = useMemo(() => {
    return debounce(handlePeriodSearchDebounce, DEBOUNCE_TIME);
  }, []);

  // to clear debounce
  useEffect(() => {
    return () => {
      artitstSearchDebounce.cancel();
      periodSearchDebounce.cancel();
    };
  }, [artitstSearchDebounce, periodSearchDebounce]);

  const handleMultiSelectDropdown = (selectedItem, dropDownOptions, key, setState) => {
    if (checkSelectAllSelected(selectedItem)) {
      setState([...CATEGORIES_OPTIONS]);
      validation.setFieldValue(
        key,
        dropDownOptions?.map((item) => item?.value)
      );
    } else {
      if (selectedItem.length > 0) {
        setState(selectedItem);
        validation.setFieldValue(key, getValuesFromArray(selectedItem) || []);
      } else {
        setState("");
        validation.setFieldValue(key, []);
      }
    }
  };

  // to set default vat option
  useEffect(() => {
    if (vats.length > 0) {
      const vat = vats?.find((vat) => vat?.rate === 23);
      setDefaultVAT(vat?.id || null);
    }
  }, [vats]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange: true,

    initialValues: {
      pre_contract: id,
      position_pre_contract:
        itemId && precontractItem?.position_pre_contract ? precontractItem?.position_pre_contract : latestPosition,
      categories:
        ((itemId || itemIdToCopy) &&
          precontractItem?.categories &&
          precontractItem?.categories?.map((item) => item?.id)) ||
        [],
      author: (itemId || itemIdToCopy) && precontractItem?.author?.id ? precontractItem?.author : "",
      title_en: (itemId || itemIdToCopy) && precontractItem?.title_en ? precontractItem?.title_en : "",
      description_en:
        (itemId || itemIdToCopy) && precontractItem?.description_en ? precontractItem?.description_en : "",
      period: (itemId || itemIdToCopy) && precontractItem?.period?.id ? precontractItem?.period : "",
      dimensions_en:
        (itemId || itemIdToCopy) && precontractItem?.dimensions_en !== null ? precontractItem?.dimensions_en : "",
      weight_en: (itemId || itemIdToCopy) && precontractItem?.weight_en !== null ? precontractItem?.weight_en : "",
      notes_en: (itemId || itemIdToCopy) && precontractItem?.notes_en ? precontractItem?.notes_en : "",
      internal_observations:
        (itemId || itemIdToCopy) && precontractItem?.internal_observations
          ? precontractItem?.internal_observations
          : "",
      described: (itemId || itemIdToCopy) && precontractItem?.described ? precontractItem?.described : false,
      value_to_be_agreed:
        (itemId || itemIdToCopy) && precontractItem?.value_to_be_agreed ? precontractItem?.value_to_be_agreed : false,
      min_estimate:
        (itemId || itemIdToCopy) && precontractItem?.min_estimate !== null ? precontractItem?.min_estimate : "",
      max_estimate:
        (itemId || itemIdToCopy) && precontractItem?.max_estimate !== null ? precontractItem?.max_estimate : "",
      vat: (itemId || itemIdToCopy) && precontractItem?.vat?.id ? precontractItem?.vat?.id : defaultVAT || "",
      expenses:
        (itemId || itemIdToCopy) && precontractItem?.expenses.length
          ? generateExpensesData(precontractItem?.expenses)
          : [],
      title_pt: (itemId || itemIdToCopy) && precontractItem?.title_pt ? precontractItem?.title_pt : "",
      description_pt:
        (itemId || itemIdToCopy) && precontractItem?.description_pt ? precontractItem?.description_pt : "",
      dimensions_pt: (itemId || itemIdToCopy) && precontractItem?.dimensions_pt ? precontractItem?.dimensions_pt : "",
      weight_pt: (itemId || itemIdToCopy) && precontractItem?.weight_pt ? precontractItem?.weight_pt : "",
      notes_pt: (itemId || itemIdToCopy) && precontractItem?.notes_pt ? precontractItem?.notes_pt : "",
      metal: itemId && precontractItem?.metal ? precontractItem?.metal : "",
    },
    validationSchema: Yup.object({
      position_pre_contract: Yup.number()
        .integer(
          props.t("validation.formatGeneral", {
            enterOrSelect: props?.t("common.enter"),
            field: props?.t("common.position"),
          })
        )
        .min(1, props.t("validation.numberMin", { totalNumber: 1 }))
        .required(
          props.t("validation.generalField", {
            promptType: props?.t("common.enter"),
            fieldType: props?.t("common.position"),
          })
        ),
      categories: Yup.array()
        .min(1, `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.category")}`)
        .required(`${props.t("common.please")} ${props.t("common.select")} ${props.t("common.category")}`),
      min_estimate: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .test(
          "max_estimate",
          props.t("validation.numberMaxStrict", {
            totalNumber: props.t("common.maximumEstimate"),
          }),
          (value) => {
            if (validation?.values?.max_estimate) {
              return (validation?.values?.min_estimate || 0) < (validation?.values?.max_estimate || 0);
            }
            return true;
          }
        )
        .test(
          "max_estimate",
          `${props.t("common.please")}  ${props.t("common.enter")} ${props.t("common.minimumEstimate")} `,
          (value) => {
            if (validation?.values?.min_estimate >= 0 && validation?.values?.min_estimate !== "") {
              return true;
            }
            return (validation?.values?.max_estimate || 0) <= 0;
          }
        ),
      max_estimate: Yup.number()
        .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
        .test(
          "min_estimate",
          props.t("validation.numberMinStrict", {
            totalNumber: props.t("common.minimumEstimate"),
          }),
          (value) => {
            if (validation?.values?.min_estimate) {
              return (validation?.values?.min_estimate || 0) < (validation?.values?.max_estimate || 0);
            }
            return true;
          }
        ),
      title_pt: Yup.string().required(
        `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.title")}`
      ),
      expenses: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required(
            `${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.description")}`
          ),
          value: Yup.number()
            .typeError(
              props.t("validation.formatGeneral", {
                enterOrSelect: props.t("common.enter"),
                field: props.t("common.value"),
              })
            )
            .min(0, props.t("validation.numberMin", { totalNumber: 0 }))
            .required(`${props.t("common.please")} ${props.t("common.enter")} ${props.t("common.value")}`),
          currency_id: Yup.number().required(
            `${props.t("common.please")} ${props.t("common.select")} ${props.t("common.currency")}`
          ),
        })
      ),
    }),

    onSubmit: (values) => {
      const payload = {
        ...values,
        appraisal_photos: appraisalPhotos,
        author: values?.author?.id || "",
        period: values?.period?.id || "",
      };
      if (itemId) {
        setSaveLoader(true);
        dispatch(updatePrecontractItem(itemId, payload)).then((res) => {
          if (res) {
            navigateOnPrecontract();
          }
          setSaveLoader(false);
        });
      } else {
        setSaveLoader(true);
        dispatch(addPrecontractItem(payload)).then((res) => {
          if (res) {
            navigateOnPrecontract();
          } else {
            validation.setFieldValue("position_pre_contract", latestPosition);
          }
          setSaveLoader(false);
        });
      }
    },
  });

  // handle category modal open
  const handleCategoryModalOpen = (data) => {
    setIsCategoryModalOpen(true);
    setSelectedItem(data);
  };

  // handle category modal close
  const handleCategoryModalClose = () => {
    setIsCategoryModalOpen(false);
    setSelectedItem(null);
  };

  // handle category save
  const handleCategorySave = (values, formikValidation) => {
    // check for add or edit
    if (selectedItem && selectedItem?.id) {
      // edit category
      setModalSaveLoader(true);
      dispatch(updateCategory(selectedItem?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            handleCategoryModalClose();
            formikValidation.resetForm();
            setSelectedCategories((prevState) => [
              {
                label: getColumnDetail(res?.category_name_en, res?.category_name_pt),
                value: prevState[0]?.value,
              },
            ]);
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    } else {
      // add category
      setModalSaveLoader(true);
      dispatch(addCategory(values))
        .then((res) => {
          if (res) {
            // success handler
            handleCategoryModalClose();
            formikValidation.resetForm();
            validation.setFieldValue("categories", [res?.id, ...validation?.values?.categories]);
            setSelectedCategories((prevState) => [
              {
                label: getColumnDetail(res?.category_name_en, res?.category_name_pt),
                value: res?.id,
              },
              ...prevState,
            ]);
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    }
  };

  // handle artist modal open
  const handleArtistModalOpen = (data) => {
    setIsArtistModalOpen(true);
    setSelectedItem(data);
  };

  // handle artist modal close
  const handleArtistModalClose = () => {
    setIsArtistModalOpen(false);
    setSelectedItem(null);
  };

  // handle artist save
  const handleArtistSave = (values, formikValidation) => {
    // check for add or edit
    if (selectedItem && selectedItem?.id) {
      // edit category
      setModalSaveLoader(true);
      dispatch(updateArtist(selectedItem?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            validation.setFieldValue("author", res);
            handleArtistModalClose();
            formikValidation.resetForm();
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    } else {
      // add category
      setModalSaveLoader(true);
      dispatch(addArtist(values))
        .then((res) => {
          if (res) {
            // success handler
            validation.setFieldValue("author", res);
            handleArtistModalClose();
            formikValidation.resetForm();
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    }
  };

  // handle period modal open
  const handlePeriodModalOpen = (data) => {
    setIsPeriodModalOpen(true);
    setSelectedItem(data);
  };

  // handle period modal close
  const handlePeriodModalClose = () => {
    setIsPeriodModalOpen(false);
    setSelectedItem(null);
  };

  // handle period save
  const handlePeriodSave = (values, formikValidation) => {
    // check for add or edit
    if (selectedItem && selectedItem?.id) {
      // edit period
      setModalSaveLoader(true);
      dispatch(updatePeriod(selectedItem?.id, values))
        .then((res) => {
          if (res) {
            // success handler
            validation.setFieldValue("period", res);
            handlePeriodModalClose();
            formikValidation.resetForm();
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    } else {
      // add period
      setModalSaveLoader(true);
      dispatch(addPeriod(values))
        .then((res) => {
          if (res) {
            // success handler
            handlePeriodModalClose();
            formikValidation.resetForm();
            validation.setFieldValue("period", res);
          } else {
            // failure handler
          }
          setModalSaveLoader(false);
        })
        .catch(() => {
          setModalSaveLoader(false);
        });
    }
  };

  //   handle reset item info form
  const handleResetItemInfoForm = () => {
    // prompt before reetting item
    if (
      (precontractItem?.appraisal_photos?.length || 0) !== appraisalPhotos?.length ||
      (!validation?.isSubmitting && JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
    ) {
      if (window.confirm(props.t("confirmation.resetMessage"))) {
        validation.resetForm();
        if (precontractItem?.appraisal_photos.length > 0 && itemId) {
          setAppraisalPhotos(precontractItem.appraisal_photos);
        } else {
          setAppraisalPhotos([]);
        }
        setDropdown();
      }
    }
  };

  // navigate on precontract item
  const navigateOnPrecontract = () => {
    history.push(`/contracts/pre-contracts/edit/${id}`);
  };

  // to pre-filled the appraisal photos
  useEffect(() => {
    if (precontractItem?.appraisal_photos.length > 0 && itemId) {
      setAppraisalPhotos(precontractItem.appraisal_photos);
    } else {
      setAppraisalPhotos([]);
    }
  }, [itemId, itemIdToCopy, precontractItem]);

  // to get the list of lots categories
  useEffect(() => {
    dispatch(getCategories(categoriesPayload));
  }, [dispatch, categoriesPayload]);

  // to get the list of lots artists
  useEffect(() => {
    handleGetArtists(artistsPayload);
  }, [artistsPayload, handleGetArtists]);

  // to get the list of lots periods
  useEffect(() => {
    setPeriodSearchLoader(true);
    dispatch(getPeriods(periodsPayload)).then(() => {
      setPeriodSearchLoader(false);
    });
  }, [dispatch, periodsPayload]);

  // to get the list of the vats
  useEffect(() => {
    dispatch(getVATs(vatsPayload));
  }, [dispatch, vatsPayload]);

  //   to get the precontract data
  useEffect(() => {
    if (id) {
      dispatch(getPrecontractById(id)).then((res) => {
        if (!res) {
          history.push(`/contracts/pre-contracts`);
        }
      });
    }
  }, [dispatch, id, history]);

  const handleGetPrecontractItemById = useCallback(
    (itemId) => {
      if (itemId) {
        setItemInfoLoader(true);
        dispatch(getPrecontractItemById(itemId)).then((res) => {
          if (!res) {
            history.push(`/contracts/pre-contracts`);
          }
          setItemInfoLoader(false);
        });
      }
    },
    [dispatch, history]
  );

  const setDropdown = useCallback(() => {
    if (itemId || itemIdToCopy) {
      setSelectedCategories(
        getLabelValueArray(
          categoryOptions.filter((list) => validation?.values?.categories?.some((item) => item === list?.id)),
          "id",
          i18next.resolvedLanguage === "pt" ? "category_name_pt" : "category_name_en"
        )
      );
    } else {
      setSelectedCategories([]);
    }
  }, [categoryOptions, itemId, itemIdToCopy, validation?.values?.categories]);

  // to set all state of form
  useEffect(() => {
    if (itemId || itemIdToCopy) {
      setDropdown();
    }
  }, [itemId, itemIdToCopy, setDropdown]);

  // to get the precontract item data
  useEffect(() => {
    if (itemId) {
      handleGetPrecontractItemById(itemId);
    }
  }, [handleGetPrecontractItemById, itemId]);

  // to get the precontract item data for pre-filling for copy action
  useEffect(() => {
    if (itemIdToCopy) {
      handleGetPrecontractItemById(itemIdToCopy);
    }
  }, [handleGetPrecontractItemById, itemIdToCopy]);

  document.title = `Veritas | ${props.t("sidebarMenu.pre-contracts")}`;

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_PRECONTRACT_ITEM });
    };
  }, [dispatch]);

  return (
    <>
      <Prompt
        when={
          (!validation?.isSubmitting && (precontractItem?.appraisal_photos?.length || 0) !== appraisalPhotos?.length) ||
          (!validation?.isSubmitting &&
            JSON.stringify(validation?.initialValues) !== JSON.stringify(validation?.values))
        }
        message={props.t("confirmation.formLeaveMsg")}
      />
      <FormikProvider value={validation}>
        <div className="page-content layout-main-container">
          <div className="container-header">
            <CustomBreadcrumb
              data={[
                {
                  label: props.t("sidebarMenu.pre-contracts"),
                  link: "/contracts/pre-contracts",
                },
                {
                  label: precontractData?.customer
                    ? `${precontractData?.pre_contract_no} (${getOnlyCustomerName(precontractData?.customer)})`
                    : precontractData?.pre_contract_no,
                  link: `/contracts/pre-contracts/edit/${id}`,
                },
                {
                  label: props.t(`${itemId ? "common.editTitle" : "common.addTitle"}`, {
                    module: `${props.t("contracts.precontracts.precontract")} ${props.t("common.item")}`,
                  }),
                },
              ]}
            />
          </div>
          <Card className="container-body card-section">
            {/* item info form */}
            {itemInfoLoader ? <Loader /> : null}
            <Form
              onKeyPress={onKeyPressForm}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <CardHeader className="card-section-header">
                <span className="card-title">{`${props.t("common.item")} ${props.t("common.info")}`}</span>
                <div className="d-flex flex-row justify-content-end gap-2">
                  <CustomButton
                    color="dark"
                    btnTitle={props.t("common.reset")}
                    disabled={saveLoader}
                    onClick={handleResetItemInfoForm}
                  />
                  <CustomButton
                    color="primary"
                    disabled={saveLoader}
                    btnTitle={props.t("common.save")}
                    type="submit"
                    onClick={() => scrollToFirstFormikError(validation.errors)}
                    isLoading={saveLoader}
                  />
                </div>
              </CardHeader>
              <CardBody className="card-section-body">
                {/* position contract and artist */}
                <Row>
                  <Col className="col-sm-12 col-md-6 col-xl-4">
                    <Row>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("common.positionInPrecontract")}</Label>
                        <InputGroup>
                          <InputGroupText>{precontractData?.pre_contract_no + "/"}</InputGroupText>
                          <NumberInput
                            name="position_pre_contract"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.position"),
                            })}
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.position_pre_contract}
                            invalid={
                              validation.touched.position_pre_contract && validation.errors.position_pre_contract
                                ? true
                                : false
                            }
                          />
                          {validation.touched.position_pre_contract && validation.errors.position_pre_contract ? (
                            <FormFeedback type="invalid">{validation.errors.position_pre_contract}</FormFeedback>
                          ) : null}
                        </InputGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="author" className="form-label">
                          {props.t("common.artist")}
                        </Label>
                        <div className="d-flex flex-row gap-1">
                          <Select
                            isClearable
                            isLoading={artistSearchLoader}
                            name="author"
                            options={artistSearchLoader ? [] : ARTISTS_OPTIONS}
                            getOptionLabel={(option) => option?.description}
                            getOptionValue={(option) => option?.id}
                            className={`custom-select w-100 ${
                              validation.touched.author && validation.errors.author ? "select-error" : ""
                            }`}
                            placeholder={props.t("placeholder.selectField", {
                              fieldType: props.t("common.artist"),
                            })}
                            value={validation.values.author || ""}
                            onInputChange={artitstSearchDebounce}
                            onChange={(author) => {
                              validation.setFieldValue("author", author?.id ? author : "");
                            }}
                            onBlur={(e) => {
                              validation.setFieldTouched("author", true);
                              validation.handleBlur(e);
                            }}
                          />
                          <ActionButton
                            iconClasses={"ri-add-circle-fill fs-18"}
                            tooltip={`${props.t("common.add")} ${props.t("common.artist")}`}
                            buttonId={"author-add"}
                            onClick={() => {
                              handleArtistModalOpen();
                            }}
                          />
                          <ActionButton
                            iconClasses={"ri-pencil-fill fs-18"}
                            tooltip={`${props.t("common.edit")} ${props.t("common.artist")}`}
                            buttonId={"author-edit"}
                            disabled={!validation?.values?.author}
                            onClick={() => {
                              handleArtistModalOpen(validation?.values?.author);
                            }}
                          />
                        </div>
                        {validation.touched.author && validation.errors.author ? (
                          <span className="custom-invalid-feedback">{validation.errors.author}</span>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>

                <Row>
                  <Col sm={12} md={12} lg={8} xl={8} xxl={8}>
                    {/* title pt-en */}
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="title_pt" className="form-label fw-bold">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.title")} ${props.t("common.pt")}`
                              : `${props.t("common.title")}`}
                            {" *"}
                          </Label>
                          <Input
                            name="title_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.title"),
                            })}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title_pt}
                            invalid={validation.touched.title_pt && validation.errors.title_pt ? true : false}
                          />
                          {validation.touched.title_pt && validation.errors.title_pt ? (
                            <FormFeedback type="invalid">{validation.errors.title_pt}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="title_en" className="form-label fw-bold">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.title")} `
                              : `${props.t("common.title")} ${props.t("common.en")}`}
                          </Label>
                          <Input
                            name="title_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.title"),
                            })}
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title_en}
                            invalid={validation.touched.title_en && validation.errors.title_en ? true : false}
                          />
                          {validation.touched.title_en && validation.errors.title_en ? (
                            <FormFeedback type="invalid">{validation.errors.title_en}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {/* description pt-en */}
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="description_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.description")} ${props.t("common.pt")}`
                              : `${props.t("common.description")}`}
                          </Label>
                          <textarea
                            name="description_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.description"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description_pt}
                            invalid={
                              validation.touched.description_pt && validation.errors.description_pt ? true : false
                            }
                            rows={TEXTAREA_DEFAULT_ROWS}
                          />
                          {validation.touched.description_pt && validation.errors.description_pt ? (
                            <span className="custom-invalid-feedback">{validation.errors.description_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="description_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.description")} `
                              : `${props.t("common.description")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="description_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.description"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description_en}
                            invalid={
                              validation.touched.description_en && validation.errors.description_en ? true : false
                            }
                            rows={TEXTAREA_DEFAULT_ROWS}
                          />
                          {validation.touched.description_en && validation.errors.description_en ? (
                            <span className="custom-invalid-feedback">{validation.errors.description_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {/* category, period, metal */}
                    <Row>
                      <Col className="col-sm-12 col-md-12 col-xl-4">
                        <div className="mb-3">
                          <Label htmlFor="category" className="form-label">
                            {props.t("common.category") + "*"}
                          </Label>
                          <div className="d-flex flex-row gap-1">
                            <Select
                              name="categories"
                              options={[SELECT_ALL_OPTION, ...CATEGORIES_OPTIONS]}
                              className={`w-100`}
                              placeholder={props.t("placeholder.selectField", {
                                fieldType: props.t("common.category"),
                              })}
                              value={selectedCategories || []}
                              onChange={(category) => {
                                handleMultiSelectDropdown(
                                  category,
                                  CATEGORIES_OPTIONS,
                                  "categories",
                                  setSelectedCategories
                                );
                              }}
                              onBlur={(e) => {
                                validation.setFieldTouched("categories", true);
                                validation.handleBlur(e);
                              }}
                              components={{ Option }}
                              isMulti={true}
                              hideSelectedOptions={false}
                              closeMenuOnSelect={false}
                            />
                            <ActionButton
                              // disabled={true}
                              iconClasses={"ri-add-circle-fill fs-18"}
                              tooltip={`${props.t("common.add")} ${props.t("common.category")}`}
                              buttonId="category-add"
                              onClick={() => {
                                handleCategoryModalOpen();
                              }}
                            />
                            <ActionButton
                              iconClasses={"ri-pencil-fill fs-18"}
                              tooltip={`${props.t("common.edit")} ${props.t("common.category")}`}
                              buttonId="category-edit"
                              disabled={validation?.values?.categories?.length !== 1}
                              onClick={() => {
                                handleCategoryModalOpen(
                                  categories?.find((category) => validation?.values?.categories[0] === category?.id)
                                );
                              }}
                            />
                          </div>
                          {validation.touched.categories && validation.errors.categories ? (
                            <span className="custom-invalid-feedback">{validation.errors.categories}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col className="col-sm-12 col-md-12 col-xl-4">
                        <div className="mb-3">
                          <Label htmlFor="period" className="form-label">
                            {props.t("common.period")}
                          </Label>
                          <div className="d-flex flex-row gap-1">
                            <Select
                              isClearable
                              isLoading={periodSearchLoader}
                              name="period"
                              options={periodSearchLoader ? [] : PERIODS_OPTIONS}
                              getOptionLabel={(option) => getColumnDetail(option?.title_en, option?.title_pt)}
                              getOptionValue={(option) => option?.id}
                              className={`custom-select w-100 ${
                                validation.touched.period && validation.errors.period ? "select-error" : ""
                              }`}
                              placeholder={props.t("placeholder.selectField", {
                                fieldType: props.t("common.period"),
                              })}
                              value={validation.values.period || ""}
                              onInputChange={periodSearchDebounce}
                              onChange={(period) => {
                                validation.setFieldValue("period", period?.id ? period : "");
                              }}
                              onBlur={(e) => {
                                validation.setFieldTouched("period", true);
                                validation.handleBlur(e);
                              }}
                            />
                            <ActionButton
                              iconClasses={"ri-add-circle-fill fs-18"}
                              tooltip={`${props.t("common.add")} ${props.t("common.period")}`}
                              buttonId={"period-add"}
                              onClick={() => {
                                handlePeriodModalOpen();
                              }}
                            />
                            <ActionButton
                              iconClasses={"ri-pencil-fill fs-18"}
                              tooltip={`${props.t("common.edit")} ${props.t("common.period")}`}
                              buttonId={"period-edit"}
                              disabled={!validation?.values?.period}
                              onClick={() => {
                                handlePeriodModalOpen(validation?.values?.period);
                              }}
                            />
                          </div>
                          {validation.touched.period && validation.errors.period ? (
                            <span className="custom-invalid-feedback">{validation.errors.period}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col className="col-sm-12 col-md-12 col-xl-4">
                        <div className="mb-3">
                          <Label htmlFor="metal" className="form-label">
                            {props.t("common.metal")}
                          </Label>
                          <div className="d-flex flex-row gap-1">
                            <Select
                              isClearable
                              name="metal"
                              options={METAL_OPTIONS}
                              className={`custom-select w-100 ${
                                validation.touched.metal && validation.errors.metal ? "select-error" : ""
                              }`}
                              placeholder={props.t("placeholder.selectField", {
                                fieldType: props.t("common.metal"),
                              })}
                              value={getTypeFromValue(METAL_OPTIONS, validation.values.metal) || ""}
                              onChange={(metal) => {
                                validation.setFieldValue("metal", metal?.value ? metal?.value : "");
                              }}
                              onBlur={(e) => {
                                validation.setFieldTouched("metal", true);
                                validation.handleBlur(e);
                              }}
                            />
                          </div>

                          {validation.touched.metal && validation.errors.metal ? (
                            <span className="custom-invalid-feedback">{validation.errors.metal}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <hr className="opacity-50" />
                    {/* Dimensions  */}
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="dimensions_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.dimensions")} ${props.t("common.pt")}`
                              : `${props.t("common.dimensions")}`}
                            {` (${props.t("common.cm")})`}
                          </Label>
                          <textarea
                            name="dimensions_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.dimensions"),
                            })}
                            rows={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dimensions_pt}
                            invalid={validation.touched.dimensions_pt && validation.errors.dimensions_pt ? true : false}
                          />
                          {validation.touched.dimensions_pt && validation.errors.dimensions_pt ? (
                            <span type="invalid">{validation.errors.dimensions_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="dimensions_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.dimensions")}`
                              : `${props.t("common.dimensions")} ${props.t("common.en")}`}
                            {` (${props.t("common.cm")})`}
                          </Label>
                          <textarea
                            name="dimensions_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.dimensions"),
                            })}
                            rows={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dimensions_en}
                            invalid={validation.touched.dimensions_en && validation.errors.dimensions_en ? true : false}
                          />
                          {validation.touched.dimensions_en && validation.errors.dimensions_en ? (
                            <span type="invalid">{validation.errors.dimensions_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {/* Weight  */}
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="weight_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.weight")} ${props.t("common.pt")}`
                              : `${props.t("common.weight")}`}
                            {` (${props.t("common.g")})`}
                          </Label>
                          <textarea
                            name="weight_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.weight"),
                            })}
                            row={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.weight_pt}
                            invalid={validation.touched.weight_pt && validation.errors.weight_pt ? true : false}
                          />
                          {validation.touched.weight_pt && validation.errors.weight_pt ? (
                            <span type="invalid">{validation.errors.weight_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="weight_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.weight")}`
                              : `${props.t("common.weight")} ${props.t("common.en")}`}
                            {` (${props.t("common.g")})`}
                          </Label>
                          <textarea
                            name="weight_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.weight"),
                            })}
                            row={2}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.weight_en}
                            invalid={validation.touched.weight_en && validation.errors.weight_en ? true : false}
                          />
                          {validation.touched.weight_en && validation.errors.weight_en ? (
                            <span type="invalid">{validation.errors.weight_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <hr className="opacity-50" />
                    {/* notes pt-en */}
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="notes_pt" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.notes")} ${props.t("common.pt")}`
                              : `${props.t("common.notes")}`}
                          </Label>
                          <textarea
                            name="notes_pt"
                            className="form-control"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.notes"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.notes_pt}
                            invalid={validation.touched.notes_pt && validation.errors.notes_pt ? true : false}
                            rows={TEXTAREA_DEFAULT_ROWS}
                          />
                          {validation.touched.notes_pt && validation.errors.notes_pt ? (
                            <span className="custom-invalid-feedback">{validation.errors.notes_pt}</span>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="notes_en" className="form-label">
                            {i18next.resolvedLanguage === "en"
                              ? `${props.t("common.notes")} `
                              : `${props.t("common.notes")} ${props.t("common.en")}`}
                          </Label>
                          <textarea
                            name="notes_en"
                            className="form-control en-field-bg"
                            placeholder={props.t("placeholder.enterField", {
                              fieldType: props.t("common.notes"),
                            })}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.notes_en}
                            invalid={validation.touched.notes_en && validation.errors.notes_en ? true : false}
                            rows={TEXTAREA_DEFAULT_ROWS}
                          />
                          {validation.touched.notes_en && validation.errors.notes_en ? (
                            <span className="custom-invalid-feedback">{validation.errors.notes_en}</span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    {/* internal obervation */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="internal_observations" className="form-label">
                          {props.t("common.internalObservations")}
                        </Label>
                        <textarea
                          name="internal_observations"
                          className="form-control"
                          placeholder={props.t("placeholder.enterField", {
                            fieldType: props.t("common.internalObservations"),
                          })}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.internal_observations}
                          invalid={
                            validation.touched.internal_observations && validation.errors.internal_observations
                              ? true
                              : false
                          }
                          rows={TEXTAREA_DEFAULT_ROWS}
                        />
                        {validation.touched.internal_observations && validation.errors.internal_observations ? (
                          <span className="custom-invalid-feedback">{validation.errors.internal_observations}</span>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                  <Col sm={12} md={12} lg={4} xl={4} xxl={4}>
                    {/* described switch */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="described" className="form-label">
                          {`${props.t("common.described")}`}
                        </Label>
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            role="switch"
                            id="described"
                            name="described"
                            className="form-check-input"
                            onChange={validation.handleChange}
                            checked={validation.values.described}
                          />
                        </div>
                      </div>
                    </Row>
                    <hr className="opacity-50" />
                    {/* min estimate */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="min_estimate" className="form-label fw-bold">
                          {props.t("common.minimumEstimate")}
                        </Label>
                        <NumberInput
                          name="min_estimate"
                          className="form-control"
                          placeholder={props.t("placeholder.enterField", {
                            fieldType: props.t("common.minimumEstimate"),
                          })}
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.min_estimate}
                          invalid={validation.touched.min_estimate && validation.errors.min_estimate ? true : false}
                        />
                        {validation.touched.min_estimate && validation.errors.min_estimate ? (
                          <FormFeedback type="invalid">{validation.errors.min_estimate}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* max estimate */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="max_estimate" className="form-label fw-bold">
                          {props.t("common.maximumEstimate")}
                        </Label>
                        <NumberInput
                          name="max_estimate"
                          className="form-control"
                          placeholder={props.t("placeholder.enterField", {
                            fieldType: props.t("common.maximumEstimate"),
                          })}
                          type="number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.max_estimate}
                          invalid={validation.touched.max_estimate && validation.errors.max_estimate ? true : false}
                        />
                        {validation.touched.max_estimate && validation.errors.max_estimate ? (
                          <FormFeedback type="invalid">{validation.errors.max_estimate}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                    {/* value to be agreed */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="value_to_be_agreed" className="form-label">
                          {`${props.t("common.valueToBeAgreed")}`}
                        </Label>
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            role="switch"
                            id="value_to_be_agreed"
                            name="value_to_be_agreed"
                            className="form-check-input"
                            onChange={validation.handleChange}
                            checked={validation.values.value_to_be_agreed}
                          />
                        </div>
                      </div>
                    </Row>
                    <hr className="opacity-50" />
                    {/* vat */}
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="vat" className="form-label">
                          {props.t("common.vat_another")}
                        </Label>
                        <Select
                          isClearable
                          name="vat"
                          options={VAT_OPTIONS}
                          className={`custom-select ${
                            validation.touched.vat && validation.errors.vat ? "select-error" : ""
                          }`}
                          placeholder={props.t("placeholder.enterField", {
                            fieldType: props.t("common.vat_another"),
                          })}
                          value={getTypeFromValue(VAT_OPTIONS, validation.values.vat) || ""}
                          onChange={(vat) => {
                            validation.setFieldValue("vat", vat?.value ? vat?.value : "");
                          }}
                          onBlur={(e) => {
                            validation.setFieldTouched("vat", true);
                            validation.handleBlur(e);
                          }}
                        />
                        {validation.touched.vat && validation.errors.vat ? (
                          <span className="custom-invalid-feedback">{validation.errors.vat}</span>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                </Row>
                <hr className="opacity-50" />
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="photos" className="form-label fw-bold">
                      {props.t("common.photos")}
                    </Label>
                  </div>
                </Row>
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="appraisal_photos" className="form-label">
                      {`${props.t("common.appraisal")}`}
                    </Label>
                    <CustomDropDown setFiles={setAppraisalPhotos} files={appraisalPhotos} />
                  </div>
                </Row>
                <hr className="opacity-50" />
                <Row>
                  <div className="mb-3">
                    <Label htmlFor="expenses" className="form-label">
                      {`${props.t("common.expenses")}`}
                    </Label>
                    <ExpensesForm validation={validation} />
                  </div>
                </Row>
              </CardBody>
              <CardFooter>
                <div className="d-flex flex-row justify-content-end gap-2">
                  <CustomButton
                    color="dark"
                    btnTitle={props.t("common.reset")}
                    disabled={saveLoader}
                    onClick={handleResetItemInfoForm}
                  />
                  <CustomButton
                    color="primary"
                    disabled={saveLoader}
                    btnTitle={props.t("common.save")}
                    type="submit"
                    isLoading={saveLoader}
                  />
                </div>
              </CardFooter>
            </Form>
          </Card>
        </div>
      </FormikProvider>
      {/* category modal */}
      <AddCategoryModal
        category={selectedItem}
        isModalOpen={isCategoryModalOpen}
        onCancel={handleCategoryModalClose}
        onSave={handleCategorySave}
        loader={modalSaveLoader}
      />
      {/* artist modal */}
      <AddArtistModal
        artist={selectedItem}
        isModalOpen={isArtistModalOpen}
        onCancel={handleArtistModalClose}
        onSave={handleArtistSave}
        loader={modalSaveLoader}
      />
      {/* period modal */}
      <AddPeriodModal
        period={selectedItem}
        isModalOpen={isPeriodModalOpen}
        onCancel={handlePeriodModalClose}
        onSave={handlePeriodSave}
        loader={modalSaveLoader}
      />
    </>
  );
};

export default withTranslation()(PreContractItemForm);
