import { toast } from "react-toastify";
import API from "./../../helpers/api/api_interceptor";
import {
  SET_AUCTION_LOT_TAG_OPTIONS,
  SET_CUSTOMERS_DATA,
  SET_CUSTOMER_DETAILS,
  SET_PERSON_DETAILS,
  SET_SHIPPING_OPTIONS,
  SET_TAG_OPTIONS,
} from "./actionType";

// get customer list
export const getCustomerList = (params, avoidGlobalState, signal) => async (dispatch) => {
  try {
    const { page, limit, ...restParams } = params || {};
    return API.get(`/customers/`, {
      params: {
        page_number: page,
        page_size: limit,
        ...restParams, // search, sort, filter, exclude, is_personas, type etc!
      },
      signal,
    }).then((res) => {
      if (res && res.status && res.status === 200) {
        if (!avoidGlobalState) {
          dispatch({
            type: SET_CUSTOMERS_DATA,
            payload: {
              data: res?.data?.data?.results,
              total: res?.data?.data?.count,
              all_ids: res?.data?.data?.all_ids,
            },
          });
        }
        return res?.data?.data;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to add customer
export const addCustomer = (payload) => async (dispatch) => {
  try {
    return API.post(`/customers/`, payload).then((res) => {
      if (res && res.status && res.status === 201) {
        toast.success(res?.data?.detail);
        return true;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to update currency
export const updateCustomer =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      return API.put(`/customers/${id}`, payload).then((res) => {
        if (res && res.status && res.status === 200) {
          toast.success(res?.data?.detail);
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// to delete customer
export const deleteCustomer =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      return API.delete(`/customers/${id}`, {
        data: { ...payload },
      }).then((res) => {
        return res;
      });
    } catch (error) {
      console.log(error);
    }
  };

// get a customer details
export const getCustomerDetails =
  ({ customerId }) =>
  async (dispatch) => {
    try {
      return API.get(`/customers/${customerId}`).then((res) => {
        if (res && res?.status && res?.status === 200) {
          dispatch({
            type: SET_CUSTOMER_DETAILS,
            payload: res?.data?.data,
          });
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// get a person details
export const getPersonDetails =
  ({ personId }) =>
  async (dispatch) => {
    try {
      return API.get(`/customers/${personId}`).then((res) => {
        if (res && res?.status && res?.status === 200) {
          dispatch({
            type: SET_PERSON_DETAILS,
            payload: res?.data?.data,
          });
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

// get a customer details
export const getShippingOptions = (params) => async (dispatch) => {
  try {
    return API.get(`/customers/shipping-options`, {
      params: {
        page_number: params.page,
        page_size: params.limit,
        search: params.search,
        sort: params.sort,
        filter: params.filter,
      },
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        if (params?.filter?.type === "tag") {
          dispatch({
            type: SET_TAG_OPTIONS,
            payload: res?.data?.data,
          });
          return true;
        } else if (params?.filter?.type === "auction_lot_tag") {
          dispatch({
            type: SET_AUCTION_LOT_TAG_OPTIONS,
            payload: res?.data?.data,
          });
          return true;
        } else {
          dispatch({
            type: SET_SHIPPING_OPTIONS,
            payload: res?.data?.data,
          });
          return true;
        }
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// bulk update customer status
export const updateBulkUser = (payload) => async (dispatch) => {
  try {
    return API.put(`/customers/`, {
      search: payload?.search,
      filter: payload?.filter,
      ids: payload?.ids,
      is_active: payload?.is_active,
      is_selected_all: payload?.isAllSelected,
    }).then((res) => {
      if (res && res?.status && res?.status === 200) {
        toast.success(res?.data?.detail);
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export customers pdf
export const exportCustomers = (payload) => async (dispatch) => {
  try {
    return API.post(`/customers/export`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export customer's situation
export const exportCustomerCheckingAccount = (customerId, payload) => async (dispatch) => {
  try {
    return API.post(`/customers/checking-account/export/${customerId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to export customer's situation
export const exportCustomerSituation = (customerId, payload) => async (dispatch) => {
  try {
    return API.post(`/customers/situation/export/${customerId}`, payload).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res?.data?.data?.file;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};

// to generate the password for customer
export const generateCustomerPassword = (customerId) => async (dispatch) => {
  try {
    return API.post(`/customers/password-generate/${customerId}`).then((res) => {
      if (res && res.status && res.status === 200) {
        toast.success(res?.data?.detail);
        return res;
      } else {
        return false;
      }
    });
  } catch (error) {
    console.log(error);
  }
};
