import { REPORT_PAYLOAD } from "./constants/payloads";
import {
  CONTRACT_STATUS_OPTIONS,
  METAL_OPTIONS,
  MOVEMENT_TYPES,
  PRINTED_TYPES,
  SELECT_ALL_OPTION,
  SELECT_NONE_OPTION,
  TAG_BUREAUCRACY_OPTIONS,
  TYPE_OF_PRODUCTS,
} from "./constants/selectOptions";
// for excelsheet
import { pdf } from "@react-pdf/renderer";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import * as FileServer from "file-saver";
import i18next from "i18next";
import moment from "moment";
import XLSX from "sheetjs-style";
import { store } from "../store";
import {
  BUYING_CONTENT,
  CONTACT_US_CONTENT,
  CREDIT_NOTE,
  CREDIT_NOTE_LOT,
  DEBIT_NOTE,
  DEBIT_NOTE_LOT,
  FATURA_INVOICE,
  HOME_PAGE,
  IMAGE_EXTENSIONS,
  INVOICE_DOCUMENT,
  monthNames,
  NORMAL_INVOICE,
  NORMAL_PROFORMA,
  OTHER_PAGE,
  PDF_EXTENSIONS,
  PRIVATE_SALES,
  PROFORMA_INVOICE_DOCUMENT,
  PROFORMA_NORMAL,
  RECEIPT_DOCUMENT,
  RETURN_GUIDE_DOCUMENT,
  SALES_NOTE,
  SALES_NOTE_PROFORMA,
  SELLING_CONTENT,
  SERVICES_APPRAISALS,
  SERVICES_CONSULTANCY,
  SERVICES_EXPERTIES,
  TRANSPORT_DOCUMENT,
  VIDEO_EXTENSIONS,
  WHO_WE_ARE_CONTENT,
} from "./constants";

// to get the values from the [{value:,label:},...] array
export const getValuesFromArray = (array) => {
  return array?.map((item) => item?.value);
};

// to get the values from the [{value:,label:},...] array
export const getIDFromArray = (array) => {
  return array?.map((item) => item?.id);
};

// get main and spoken language codes with join by ','
export const getLanguageCodes = (languages) => {
  let langString = [];
  if (languages.length === 0) {
    return "-";
  }
  languages.filter((lang) => langString.push(lang.code));
  return langString.join(" , ");
};

// get label and value from array
export const getLableAndValue = (langArr) => {
  const labeledArr = [];
  langArr.map((lang) => labeledArr.push({ label: lang.code, value: lang.id }));
  return labeledArr;
};

// get label and value from array
export const getLableAndValueForPermission = (langArr) => {
  const labeledArr = [];
  langArr.map((lang) =>
    labeledArr.push({
      label: `${lang.code} - ${lang.description.charAt(0).toUpperCase() + lang.description.slice(1)}`,
      value: lang.id,
    })
  );
  return labeledArr;
};

// get selected language from options
export const getSelectedLanguages = (langList, userData, type) => {
  if (type === "main") {
    return langList.filter((list) => userData?.languages.some((lang) => lang.id === list.id));
  } else if (type === "spoken") {
    return langList.filter((list) => userData?.languages_speaks.some((lang) => lang.id === list.id));
  }
};

// document type
export const getMovementTypeFromValue = (value) => {
  return MOVEMENT_TYPES.find((movement) => movement.value === value);
};
export const getPrintedTypeFromValue = (value) => {
  return PRINTED_TYPES.find((printed) => printed.value === value);
};

// to get the label , value
export const getLabelValueArray = (array, valueField, labelField) => {
  return array?.map((item) => {
    return {
      label: item[`${labelField}`],
      value: item[`${valueField}`],
    };
  });
};

// to get the labe1+label2,value
export const getConcatinatedLabelsValueArray = (array, valueField, labelField1, labelField2, seperator) => {
  return array?.map((item) => {
    return {
      label: `${item[labelField1]}${seperator ? seperator : " "}${item[labelField2]}`,
      value: item[`${valueField}`],
    };
  });
};

// to get the vat options 'code - rate%'
export const getVATOptioins = (array, valueField, labelField1, labelField2, seperator, labelPostfix) => {
  return array.map((item) => {
    return {
      label: `${item[labelField1]}${seperator ? seperator : " "}${item[labelField2]}${
        labelPostfix ? labelPostfix : ""
      }`,
      value: item[`${valueField}`],
    };
  });
};

// to get the contract-customer full name options
export const getContractOptions = (array) => {
  return array.map((item) => {
    return {
      value: item?.id,
      label: `${item?.contract_no} - ${getOnlyCustomerName(item?.customer)}`,
    };
  });
};

// to get the customer no. - customer full name options
export const getCustomerOptions = (array) => {
  return array.map((item) => {
    return {
      value: item?.id,
      label: `${item?.customer_no} - ${getOnlyCustomerName(item)}`,
    };
  });
};

// to find the type from value label array by value
export const getTypeFromValue = (array, value) => {
  return array.find((item) => item.value === value);
};

export const checkSelectAllSelected = (optionArr) => {
  return optionArr.some((option) => option.value === SELECT_ALL_OPTION.value);
};

// check none is selected or not
export const checkNoneSelected = (optionArr) => {
  return optionArr.some((option) => option.value === SELECT_NONE_OPTION.value);
};

// get column index for report data
export const getReportDataIndex = (arr, type, str) => {
  return arr.findIndex((ele) => ele?.name === str && ele?.type === type);
};

// get field value in setting->general->report
export const getFieldValue = (arr, type, str) => {
  return arr[getReportDataIndex(arr, type, str)]?.value || "";
};

// get separate address
export const getAddress = (arr, type, str, index) => {
  return arr[getReportDataIndex(arr, type, str)]?.value.split(",")[index].trim() || "";
};

// to generate payload for report
export const generateReportPayload = (values) => {
  const payload = REPORT_PAYLOAD;
  // eslint-disable-next-line array-callback-return
  REPORT_PAYLOAD.map((ele, index) => {
    // for company name
    if (ele.name === "company_name") {
      if (ele.type === "report") {
        payload[index].value = values.report_company_name.trim();
      }
      if (ele.type === "saft") {
        payload[index].value = values.saft_company_name.trim();
      }
    }
    // for full address
    else if (ele.name === "full_address") {
      payload[index].value = values.report_full_address.trim();
    }
    // for telephone
    else if (ele.name === "telephone") {
      if (ele.type === "report") {
        payload[index].value = values.report_telephone.trim();
      }
      if (ele.type === "saft") {
        payload[index].value = values.saft_telephone.trim();
      }
    }
    // for website
    else if (ele.name === "website") {
      if (ele.type === "report") {
        payload[index].value = values.report_website.trim();
      }
      if (ele.type === "saft") {
        payload[index].value = values.saft_website.trim();
      }
    }
    // for CRC
    else if (ele.name === "CRC") {
      if (ele.type === "report") {
        payload[index].value = values.report_crc.trim();
      }
      if (ele.type === "saft") {
        payload[index].value = values.saft_crc.trim();
      }
    }
    // for vat
    else if (ele.name === "VAT") {
      payload[index].value = values.report_vat.trim();
    }
    // for country code
    else if (ele.name === "country_code") {
      payload[index].value = values.saft_country_code.trim();
    }
    // for address
    else if (ele.name === "address") {
      payload[
        index
      ].value = `${values.saft_street_address.trim()} , ${values.saft_door_address.trim()}, ${values.saft_locality_address.trim()}, ${values.saft_post_code_address.trim()}, ${values.saft_district_address.trim()}, ${values.saft_country_address.trim()}`;
    }
    // for fax
    else if (ele.name === "fax") {
      payload[index].value = values.saft_fax.trim();
    }
    // for email
    else if (ele.name === "email") {
      payload[index].value = values.saft_email.trim();
    }
    // for NIF
    else if (ele.name === "NIF") {
      payload[index].value = values.saft_nif.trim();
    }
  });

  return payload;
};

// to split the name from the url after specific index
export const extractNameFromUrl = (url, seperator, targetIndex) => {
  return url?.split(seperator)?.[targetIndex];
};

// to convert document into base 64
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const toBinary = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// to create local preview url
export const createFileUrl = (file) => {
  return URL.createObjectURL(file);
};

// to inverse the text color from bg color
export const generateContrastColor = (hexcolor) => {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split("")
      .map(function (hex) {
        return hex + hex;
      })
      .join("");
  }

  // Convert to RGB value
  let r = parseInt(hexcolor.substr(0, 2), 16);
  let g = parseInt(hexcolor.substr(2, 2), 16);
  let b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "black" : "white";
};

// get date-time string
export const getDateTimeLocale = (datetime) => {
  return datetime?.split("Z")?.[0];
};

// get date in YYYY-MM-DD format
export const getDateInYYYYMMDDFormat = (date) => {
  return `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`;
};

// get date in DD-MM-YYYY format
export const getDateInDDMMYYYYFormat = (date) => {
  return `${date?.getDate()}-${date?.getMonth() + 1}-${date?.getFullYear()}`;
};

// get date in DD-MM-YYYY format
export const getDateInMMDDYYYYFormat = (date, seperator = "-") => {
  return `${date?.getMonth() + 1}${seperator}${date?.getDate()}${seperator}${date?.getFullYear()}`;
};

// to get the YYYY-MM-DDTHH:mm format string
export const getDateTimeString = (date) => {
  return new Date(date)?.toISOString()?.slice(0, new Date(date)?.toISOString()?.lastIndexOf(":"));
};

// to get the YYYY-MM-DD string
export const getDateString = (date) => {
  return new Date(date)?.toISOString()?.split("T")?.[0];
};

// to get the YYYY-MM-DD:00:00 string
export const getDateStringWith12AM = (date) => {
  return new Date(date).toISOString().split("T")[0].concat("T00:00");
};

export const incrementDate = (date, number) => {
  // return moment().add(number, "days");
  return new Date(new Date().setDate(new Date(date).getDate() + number));
};

// get date in DD-MMM-YYYY format
export const getDateInDDMMMYYYYFormat = (date, seperator = " ") => {
  return `${date.getDate()}${seperator}${monthNames[date.getMonth()]}${seperator}${date.getFullYear()}`;
};

// get date in dd-mm-yyyy --:-- -- format
export const getDateForInput = (date) => {
  return moment(date).format("DD-MM-YYYY HH:mm ");
};

// customers
// get client address from addresses array
export const getCustomerAddress = (addressArr, addressType) => {
  const add = addressArr?.find((address) => address?.address_type === addressType);
  return `${add?.address}, ${add?.post_code}, ${add?.city}, ${add?.country?.name}` || "-";
};

// get client address object
export const getCustomerAddressObject = (addressArr, addressType) => {
  return addressArr?.find((address) => address?.address_type === addressType);
};

// get client address from addresses array
export const getCustomerAddressForExcel = (addressArr, addressType) => {
  const add = addressArr?.find((address) => address?.address_type === addressType);
  let data = [];
  if (add?.address) data.push(add?.address);
  let sub_add = [];
  if (add?.post_code) sub_add.push(add?.post_code);
  if (add?.city) sub_add.push(add?.city);
  if (sub_add?.length > 0) data.push(sub_add.join(", "));
  if (add?.country?.name) data.push(add?.country?.name);
  return data?.length > 0 ? data.join("\n") : "-";
};

export const removeFieldsFromObj = (valueObj, removeItems) => {
  removeItems.map((item) => delete valueObj[`${item}`]);
  return valueObj;
};

// for customers get Label of particular value
export const getLabelFromArray = (arr, value) => {
  return arr?.map((item) => {
    return item?.value === value ? item?.label : "";
  });
};

// for customers get id from code/label
export const getIdFromArray = (arr, label) => {
  return (
    arr?.find((item) => {
      return item?.label === label ? item?.value : null;
    })?.value || ""
  );
};

// get string with join by ','
export const getConcatedStringFromArray = (array, field) => {
  let string = [];
  if (array?.length === 0) {
    return "-";
  }
  array?.filter((lang) => string?.push(capitalizeFirstCharacterInString(lang[`${field}`])));
  return string?.join(", ");
};

// it will split with "_" and join with " " which is customizable
export const capitalizeFirstCharacterInString = (str, splitChar = "_", joinChar = " ") => {
  return str
    ?.split(splitChar)
    ?.map((ele) => ele[0].toUpperCase() + ele.substr(1))
    ?.join(joinChar);
};

// export excel sheet
export const exportToexcel = (dataObj = [], fileName = "excel-sheet", widthArr, headerColor) => {
  const excelData = JSON.parse(JSON.stringify(dataObj));
  const totalCell = Object.keys(dataObj[0]).length;

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(excelData);

  const wscols = [];
  // to style first row
  for (var Row = 0; Row <= dataObj?.length; ++Row) {
    for (var C = 0; C < totalCell; ++C) {
      let cell;
      const cell_ref = XLSX.utils.encode_cell({ c: C, r: Row });
      if (Row === 0) {
        cell = {
          v: capitalizeFirstCharacterInString(ws[cell_ref].v),
          s: {
            font: { sz: 13, bold: true },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
            border: { bottom: "thin" },
          },
        };
        if (headerColor) {
          cell.s.fill = { fgColor: { rgb: headerColor } };
        }
      } else {
        cell = {
          v: ws[cell_ref].v,
          s: {
            alignment: {
              vertical: "center",
              wrapText: true,
            },
          },
        };
      }
      if (widthArr?.length > 0) {
        wscols.push({ wch: widthArr[C] });
      } else {
        wscols.push({ wch: ws[cell_ref].v.length + 5 });
      }
      ws[cell_ref] = cell;
    }
  }
  ws["!autofilter"] = { ref: `A1:${numberToLetters(totalCell - 1)}${dataObj?.length + 1}` };
  ws["!cols"] = wscols;
  // return;
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileServer.saveAs(data, fileName + fileExtension);
};

export const numberToLetters = (num) => {
  let letters = "";
  while (num >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
};

// export excel sheet
export const exportCustomersToExcel = (dataObj = [], fileName = "excel-sheet", widthArr, headerColor) => {
  let customerExcelData = dataObj.map(({ is_persona, ...otherKeys }) => {
    return { ...otherKeys };
  });

  const excelData = JSON.parse(JSON.stringify(customerExcelData));
  const totalCell = Object.keys(customerExcelData[0]).length;

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(excelData);

  const wscols = [];
  // to style first row
  for (var Row = 0; Row <= customerExcelData?.length; ++Row) {
    for (var C = 0; C < totalCell; ++C) {
      let cell;
      const cell_ref = XLSX.utils.encode_cell({ c: C, r: Row });
      if (Row === 0) {
        cell = {
          v: capitalizeFirstCharacterInString(ws[cell_ref].v),
          s: {
            font: { sz: 13, bold: true },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },

            fill: { fgColor: { rgb: "bfbfbf" } },
          },
        };
      } else {
        cell = {
          v: ws[cell_ref].v,
          s: {
            alignment: {
              vertical: "center",
              wrapText: true,
            },
          },
        };
        if (!dataObj?.[Row - 1]?.is_persona) {
          cell.s.fill = { fgColor: { rgb: "e0e0e0" } };
        }
      }
      if (widthArr?.length > 0) {
        wscols.push({ wch: widthArr[C] });
      } else {
        wscols.push({ wch: ws[cell_ref].v.length + 5 });
      }
      cell.s.border = {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
      };
      ws[cell_ref] = cell;
    }
  }
  ws["!autofilter"] = { ref: `A1:${numberToLetters(totalCell - 1)}${customerExcelData?.length + 1}` };
  ws["!cols"] = wscols;
  // return;
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileServer.saveAs(data, fileName + fileExtension);
};

// export excel with title as first row
export const exportExcelWithTitleRow = (dataObj = [], fileName = "excel-sheet", widthArr, title) => {
  let customerExcelData = dataObj;
  const excelData = JSON.parse(JSON.stringify([customerExcelData?.[0], ...customerExcelData]));
  const totalCell = Object.keys(customerExcelData[0]).length;

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(excelData);

  const wscols = [];
  // to style first row
  for (var Row = 0; Row <= customerExcelData?.length + 1; ++Row) {
    for (var C = 0; C < totalCell; ++C) {
      let cell;
      const cell_ref = XLSX.utils.encode_cell({ c: C, r: Row });
      if (Row === 0) {
        cell = {
          v: title,
          s: {
            font: { sz: 13, bold: true },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },

            fill: { fgColor: { rgb: "bfbfbf" } },
          },
        };
      } else if (Row === 1) {
        cell = {
          v: capitalizeFirstCharacterInString(Object.keys(customerExcelData[0])?.[C]),
          s: {
            font: { sz: 13, bold: true },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },

            fill: { fgColor: { rgb: "e0e0e0" } },
          },
        };
      } else {
        cell = {
          v: ws[cell_ref].v,
          s: {
            alignment: {
              vertical: "center",
              wrapText: true,
            },
          },
        };
      }
      if (widthArr?.length > 0) {
        wscols.push({ wch: widthArr[C] });
      } else {
        wscols.push({ wch: ws?.[cell_ref]?.v?.length + 5 });
      }
      cell.s.border = {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
      };
      ws[cell_ref] = cell;
    }
  }
  const mergeCellPoints = [{ s: { r: 0, c: 0 }, e: { r: 0, c: totalCell - 1 } }];

  ws["!cols"] = wscols;
  ws["!merges"] = mergeCellPoints;
  ws["!rows"] = [{ hpt: 30 }];
  // return;
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileServer.saveAs(data, fileName + fileExtension);
};

// genrating data for the invoices excel sheet
export const generateExcelDataForInvoice = (arr) => {
  return arr?.map((item) => {
    return {
      "Document No.": item?.document_no || "",
      Date: item?.date,
      Customer: item?.customer ? getOnlyCustomerName(item?.customer) : "",
      "Document Type": item?.document_type?.description || "",
      Total: (item?.total || 0) + " €",
      "Total to settle": (item?.total_to_settle || 0) + " €",
      Paid: item?.is_paid ? i18next.t("common.yes") : i18next.t("common.no"),
      Sent: item?.is_sent ? i18next.t("common.yes") : i18next.t("common.no"),
    };
  });
};

// genrating data for the checking account in customer => excel sheet
export const generateExcelDataForCheckingAccount = (arr) => {
  return arr?.map((item) => {
    return {
      "Document No.": item?.document_no || "",
      Date: item?.date,
      Credit: (item?.total_info?.credit || 0) + " €",
      Debit: (item?.total_info?.debit || 0) + " €",
      Balance: (item?.total_info?.balance || 0) + " €",
    };
  });
};

// generating data for excel sheet from rresponse
export const generateCustomerExportData = (customers) => {
  const data = [];

  customers?.forEach((customer) => {
    if (!customer?.parent_id) data.push(customer);
    else data.push({ ...customer, is_persona: true });
  });

  return data?.map((item) => {
    return {
      [`${i18next.t("common.customer")} ${i18next.t("common.no_")}`]: item?.customer_no || "-",
      [i18next.t("common.title")]: item?.title_gen?.id
        ? getColumnDetail(item?.title_gen?.title_en, item?.title_gen?.title_pt)
        : "-",
      [i18next.t("common.name")]: getOnlyCustomerName(item),
      [i18next.t("common.vat")]: item?.customer_type === "company" ? item?.company_vat || "-" : item?.client_vat || "-",
      [`${i18next.t("common.idOrpassport")}`]: item?.passport_no || "-",
      [`${i18next.t("common.iban")}`]: item?.iban_no || "-",
      [`${i18next.t("common.contact")} 1`]: item?.contact_1 ? `+${item?.contact_1}` : "-",
      [`${i18next.t("common.contact")} 2`]: item?.contact_2 ? `+${item?.contact_2}` : "-",
      [i18next.t("common.email")]: item?.email || "-",
      [i18next.t("common.address")]:
        getCustomerAddressForExcel(item?.addresses, item?.customer_type === "company" ? "company" : "client") || "-",
      [i18next.t("common.newsletterSubscription")]: item?.is_subscribed_to_newsletter
        ? i18next.t("common.yes")
        : i18next.t("common.no"),
      [i18next.t("common.receivesTextMessage")]: item?.is_received_text
        ? i18next.t("common.yes")
        : i18next.t("common.no"),
      [i18next.t("common.TAGS")]: getConcatedStringFromArray(item?.tags, getColumnDetail("name_en", "name_pt")) || "-",
      [`${i18next.t("customers.postCardsShipping")}`]:
        getConcatedStringFromArray(item?.postcards, getColumnDetail("name_en", "name_pt")) || "-",
      [`${i18next.t("customers.cataloguesShipping")}`]:
        getConcatedStringFromArray(item?.catalogues, getColumnDetail("name_en", "name_pt")) || "-",
      [i18next.t("common.preferences")]:
        getConcatedStringFromArray(item?.preferences, getColumnDetail("name_en", "name_pt")) || "-",
      is_persona: item?.is_persona,
    };
  });
};

// get client address,city, country from addresses array
export const getCustomerAddressDetails = (addressArr, addressType) => {
  let address, postCode, city, country;
  const add = addressArr?.find((address) => address?.address_type === addressType);
  if (add?.address) address = add?.address;
  if (add?.post_code) postCode = add?.post_code;
  if (add?.city) city = add?.city;
  if (add?.country?.name) country = add?.country?.name;
  return { address, postCode, city, country };
};

// generating data for the catalogues shipping excel
export const generateCataloguesShippingExportData = (customers) => {
  return customers?.map((item) => {
    const { address, postCode, city, country } = getCustomerAddressDetails(
      item?.addresses,
      item?.customer_type === "company" ? "company" : "client"
    );
    return {
      "Cliente nº": item?.customer_no,
      "Título para comunicações": item?.title_comm?.id ? item?.title_comm?.title_pt : "-",
      Título: item?.title || "-",
      Nome: getOnlyCustomerName(item),
      "Contacto 1": item?.contact_1 ? `+${item?.contact_1}` : "-",
      "Contacto 2": item?.contact_2 ? `+${item?.contact_2}` : "-",
      Email: item?.email || "-",
      "Morada: Rua e Nº": address || "-",
      "Morada: Código Postal e Cidade": `${postCode} ${city}` || "-",
      "Morada: País": country || "-",
      "Envio de Catálogos": item?.is_catalogues_shipping ? i18next.t("common.yes") : i18next.t("common.no"),
      "Subscrição de catálogos": item?.is_subscribed_to_catalog ? i18next.t("common.yes") : i18next.t("common.no"),
      Vendedores: item?.type === "seller" ? i18next.t("common.yes") : i18next.t("common.no"),
      TAGS: getConcatedStringFromArray(item?.tags, "name_pt"),
    };
  });
};

// generating data for pdf for pre-contract-item
export const generatePrecontractItemsExportData = (items) => {
  return items.map((item) => ({
    ...item,
    min_estimate: item?.min_estimate !== null ? `${item?.min_estimate}` : "-",
    max_estimate: item?.max_estimate !== null ? `${item?.max_estimate}` : "-",
    described: item?.described ? i18next.t("common.described") : i18next.t("common.notDescribed"),
  }));
};

// generate pdf from data with component template
export const generatePDFDocument = async (component, pdfName) => {
  const blob = await pdf(component).toBlob();
  const url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
  window.open(url, "_blank", "");

  // FileServer.saveAs(blob, pdfName);
};

// extract all id's from array
export const getAllIds = (arr) => {
  let idArr = [];
  arr.map((item) => idArr.push(item?.id));
  return idArr;
};

// extract all one type of key's values from array
export const extractKeyValues = (arr, key) => {
  let valueArr = [];
  arr?.map((item) => valueArr.push(item?.[key]));
  return valueArr;
};

// get only single label and value from array
export const getSingleLabelAndValueFromArray = (arr, value) => {
  return arr?.find((item) => item?.value === value);
};

// find item from array of items by find with key
export const findByKeyFromArray = (arr, key, value) => {
  return arr.find((item) => item?.[key] === value);
};

// get the list of active status for contract item
export const getListOfActiveStatus = (data) => {
  const statusArray = [];
  CONTRACT_STATUS_OPTIONS.forEach((option) => {
    if (data?.[option?.value]) {
      statusArray.push(option);
    }
    if (option?.value === "described" && !data?.[option?.value]) {
      statusArray.unshift(CONTRACT_STATUS_OPTIONS[0]);
    }
  });
  return statusArray;
};

// get column data based on language

export const getColumnDetail = (enCol, ptCol) => {
  return i18next.resolvedLanguage !== "en" ? ptCol : enCol;
};

// generate expenses json for expenses form
export const generateExpensesData = (data) => {
  return data?.map((item) => ({
    ...item,
    currency_id: item?.currency_id ? item?.currency_id : "",
  }));
};

// get the list of active tags for lot
export const getListOfActiveTags = (arr, data) => {
  const statusArray = [];
  arr.forEach((option) => {
    if (data?.find((elem) => elem?.name === option?.value)) {
      statusArray.push(option);
    }
  });
  return statusArray;
};

// check bureaucracy in listing
export const getListOfTagsBureaucracy = (row) => {
  const tagBureaucracyArray = [];
  TAG_BUREAUCRACY_OPTIONS.forEach((elem) => {
    if (row?.contract_item?.[elem?.value]) {
      tagBureaucracyArray.push(elem);
    }
  });

  return tagBureaucracyArray;
};

export const getAuctionStatus = (arr, data) => {
  const result = arr.find((elem) => elem?.value === data);
  return result;
};

// to get the paddlenumber and full name of customer for paddle options
export const getPaddleOptions = (array) => {
  return array?.map((item) => {
    return {
      value: item?.id,
      label: `${item?.paddle_no} - ${getOnlyCustomerName(item?.customer)}`,
    };
  });
};

// to get the contract description for invoice/proforma
export const getContractItemDescriptionForInvoice = (lot) => {
  const des = [];

  if (lot?.author?.id) {
    des.push(lot?.author?.description);
  }
  if (i18next.resolvedLanguage === "pt") {
    if (lot?.title_pt) des.push(lot?.title_pt);
    if (lot?.description_pt) des.push(lot?.description_pt);
    if (lot?.metal) des.push(getTypeFromValue(METAL_OPTIONS, lot?.metal)?.label);
    if (lot?.dimensions_pt) des.push(lot?.dimensions_pt);
    if (lot?.weight_pt) des.push(lot?.weight_pt);
  } else {
    if (lot?.title_en) des.push(lot?.title_en);
    if (lot?.description_en) des.push(lot?.description_en);
    if (lot?.metal) des.push(getTypeFromValue(METAL_OPTIONS, lot?.metal)?.label);
    if (lot?.dimensions_en) des.push(lot?.dimensions_en);
    if (lot?.weight_en) des.push(lot?.weight_en);
  }

  let prefixOfDescription = [];
  if (lot?.auction_no) {
    prefixOfDescription.push(lot?.auction_no);
  }
  if (lot?.lot_no) {
    prefixOfDescription.push(lot?.lot_no);
  }

  return des?.length > 0
    ? prefixOfDescription?.length > 0
      ? `${prefixOfDescription?.join("/")} - ${des?.join(", ")}`
      : des?.join(", ")
    : lot?.prefixOfDescription?.join("/");
};

// to get the lot description for invoice/proforma
export const getLotDescriptionForInvoice = (lot) => {
  const des = [];

  if (lot?.contract_item?.author?.id) {
    des.push(lot?.contract_item?.author?.description);
  }

  if (i18next.resolvedLanguage === "pt") {
    if (lot?.contract_item?.title_pt) des.push(lot?.contract_item?.title_pt);
    if (lot?.contract_item?.description_pt) des.push(lot?.contract_item?.description_pt);
    if (lot?.contract_item?.metal) des.push(getTypeFromValue(METAL_OPTIONS, lot?.contract_item?.metal)?.label);
    if (lot?.contract_item?.dimensions_pt) des.push(lot?.contract_item?.dimensions_pt);
    if (lot?.contract_item?.weight_pt) des.push(lot?.contract_item?.weight_pt);
  } else {
    if (lot?.contract_item?.title_en) des.push(lot?.contract_item?.title_en);
    if (lot?.contract_item?.description_en) des.push(lot?.contract_item?.description_en);
    if (lot?.contract_item?.metal) des.push(getTypeFromValue(METAL_OPTIONS, lot?.contract_item?.metal)?.label);
    if (lot?.contract_item?.dimensions_en) des.push(lot?.contract_item?.dimensions_en);
    if (lot?.contract_item?.weight_en) des.push(lot?.contract_item?.weight_en);
  }

  let prefixOfDescription = [];
  if (lot?.auction_no || lot?.contract_item?.auction?.auction_no) {
    prefixOfDescription.push(lot?.auction_no || lot?.contract_item?.auction?.auction_no);
  }
  if (lot?.lot_no || lot?.contract_item?.auction_lots?.lot_no) {
    prefixOfDescription.push(
      lot?.lot_no + lot?.lot_alpha ||
        lot?.contract_item?.auction_lots?.lot_no + lot?.contract_item?.auction_lots?.lot_alpha
    );
  }

  return des?.length > 0
    ? prefixOfDescription?.length > 0
      ? `${prefixOfDescription?.join("/")} - ${des?.join(", ")}`
      : des?.join(", ")
    : lot?.prefixOfDescription?.join("/");
};

// to generate expense description for expense items
export const getExpenseDescription = (expenseItem) => {
  let data = [];
  if (expenseItem?.no) {
    data.push(expenseItem?.no);
  }
  if (expenseItem?.description) {
    data.push(expenseItem?.description);
  }
  return data?.length > 0 ? data?.join(" - ") : "-";
};
// to get the formatted document line
export const getFormattedDocumentLines = (
  data,
  doNotApplyVAT,
  documentType,
  tax_exemption_code,
  VAT_OPTIONS,
  doNotChargeInsurance
) => {
  const documentLines = data?.map((line) => {
    const item = {};
    const itemData = {};
    const vatId = doNotApplyVAT ? VAT_OPTIONS.filter((option) => option.rate === 0) : line?.vat;

    item.tax_exemption_code = tax_exemption_code ? tax_exemption_code : null;
    item.type_of_product = line?.type_of_product;
    item.quantity = line?.quantity || 1;
    item.discount = line?.discount || 0;
    item.discount_unit = line?.discount_unit;
    item.vat = vatId?.id;

    if (line?.premium) {
      item.premium = line?.premium;
    }

    itemData.type_of_product = line?.type_of_product;
    itemData.positionInContract =
      line?.positionInContract ||
      line?.auction_lot?.contract_item?.contract_item_no ||
      line?.contract_item?.contract_item_no;
    itemData.discount = line?.discount || 0;
    itemData.discount_unit = line?.discount_unit;
    itemData.vat = line?.vat?.rate || 0;
    itemData.paid_amount = line?.paid_amount || 0;
    itemData.total_to_settle = line?.total_to_settle || 0;

    // for invoice
    if (line?.invoice_id) {
      item.invoice_id = line?.invoice_id;
    }
    if (line?.invoice_item_id) {
      item.invoice_item_id = line?.invoice_item_id;
    }

    // for debit_note type
    if (line?.type_of_product === "debit_note") {
      item.amount = line?.amount;

      itemData.description = line?.debit_note?.information_text;
      itemData.amount = line?.debit_note?.total;
      itemData.final_amount = line?.amount;
    }

    // for expense items
    if (line?.type_of_product === "expense") {
      let finalAmount = line?.expense?.value;
      if (line?.vat?.rate && !doNotApplyVAT) {
        finalAmount += (line?.expense?.value * line?.vat?.rate) / 100;
      }
      finalAmount = roundTo(finalAmount);

      item.expense = line?.expense?.id;
      item.amount = finalAmount;

      // for itemData
      itemData.description = getExpenseDescription(line?.expense);
      itemData.quantity = line?.quantity;
      itemData.amount = line?.expense?.value || 0;
      itemData.final_amount = finalAmount;
    }

    // for auctions
    if (line?.type_of_product === "lot") {
      // for auction's lot
      if (line?.auction?.id) item.auction = line?.auction?.id;
      if (line?.paddle?.id) item.paddle = line?.paddle?.id;
      if (line?.auction_lot?.id) {
        const finalAmount = calculateTotalForDocumentLine(
          {
            qty: line?.quantity,
            value: line?.auction_lot?.sale_value,
            discount: line?.discount,
            discount_unit: line?.discount_unit,
            premium: line?.premium,
            vat: line?.vat?.rate,
          },
          line?.type_of_product,
          doNotApplyVAT
        );
        item.auction_lot = line?.auction_lot?.id;
        item.amount = finalAmount;

        // for item data
        itemData.description = getLotDescriptionForInvoice({
          ...line?.auction_lot,
        });
        itemData.quantity = 1;
        itemData.amount = line?.auction_lot?.sale_value || 0;
        itemData.premium = line?.premium || 0;
        itemData.final_amount = finalAmount;
        itemData.lot_no = line?.auction_lot?.lot_no;
      }

      // for contract items
      if (line?.contract?.id) item.contract = line?.contract?.id;
      if (line?.contract_item?.id) {
        let finalAmount = 0;
        let saleValue = 0;
        let insurance = 0;
        // Credit note is the reflection of sales note, so we just need to copy the same flow!
        if ([SALES_NOTE, CREDIT_NOTE].includes(documentType) && !doNotChargeInsurance) {
          saleValue = line?.contract_item?.sale_value;
          insurance = calculateInsuranceForLots(saleValue, 1, line?.vat?.rate, doNotApplyVAT);
        } else if (documentType === INVOICE_DOCUMENT) {
          saleValue = line?.contract_item?.min_estimate;
        }

        if ([SALES_NOTE, CREDIT_NOTE].includes(documentType)) {
          finalAmount = calculateTotalForDocumentLine(
            {
              qty: 1,
              value: saleValue,
              premium: line?.premium,
              insurance,
              discount: line?.discount,
              discount_unit: line?.discount_unit,
              vat: line?.vat?.rate,
            },
            line?.type_of_product,
            doNotApplyVAT,
            SALES_NOTE
          );
        } else {
          finalAmount = calculateTotalForDocumentLine(
            {
              qty: 1,
              value: saleValue,
              premium: line?.premium,
              discount: line?.discount,
              discount_unit: line?.discount_unit,
              vat: line?.vat?.rate,
            },
            line?.type_of_product,
            doNotApplyVAT
          );
        }

        item.contract_item = line?.contract_item?.id;
        item.amount = finalAmount;

        // for item data
        itemData.description = getLotDescriptionForInvoice({
          contract_item: { ...line?.contract_item },
          lot_no: line?.contract_item?.lot_no,
          lot_alpha: line?.contract_item?.lot_alpha,
        });
        itemData.quantity = 1;
        itemData.amount = saleValue || 0;
        itemData.premium = line?.premium || 0;
        itemData.insurance = insurance || 0;
        itemData.final_amount = finalAmount;
      }
    }

    // for product
    if (line?.type_of_product === "product" || line?.type_of_product === "service") {
      const finalAmount = calculateTotalForDocumentLine(
        {
          qty: line?.quantity,
          value: line?.product?.value,
          discount: line?.discount,
          discount_unit: line?.discount_unit,
          vat: line?.vat?.rate,
        },
        line?.type_of_product,
        doNotApplyVAT
      );
      item.product = line?.product?.id;
      item.amount = finalAmount;

      itemData.description = line?.product?.product_name;
      itemData.quantity = line?.quantity;
      itemData.amount = line?.product?.value || 0;
      itemData.final_amount = finalAmount;
    }

    item.itemData = itemData;

    return item;
  });

  return documentLines;
};

// to check url is image type or not
export const isImage = (url) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/gi.test(url);
};

// to calculate insurance for sale note lots
export const calculateInsuranceForLots = (value, insuranceRate, vatRate, doNotApplyVAT) => {
  let total = 0;
  if (insuranceRate) {
    total = (value * insuranceRate) / 100;
  }
  if (vatRate && !doNotApplyVAT) {
    total += (total * vatRate) / 100;
  }

  return total;
};

const round =
  (method) =>
  (number, precision = 2) => {
    if (typeof number !== "number") {
      throw new TypeError("Expected value to be a number");
    }

    if (precision === Number.POSITIVE_INFINITY) {
      return number;
    }

    if (!Number.isInteger(precision)) {
      throw new TypeError("Expected precision to be an integer");
    }

    const isRoundingAndNegative = method === "round" && number < 0;
    if (isRoundingAndNegative) {
      number = Math.abs(number);
    }

    const power = 10 ** precision;

    let result = Math[method]((number * power).toPrecision(15)) / power;

    if (isRoundingAndNegative) {
      result = -result;
    }

    return result;
  };

export const roundTo = round("round");
export const roundToUp = round("ceil");
export const roundToDown = round("floor");

export const getPremiumAmountForLot = (values, isChecking) => {
  if (isChecking) return +values?.premium >= 0 || +values?.auction?.buyer_premium >= 0;
  return (
    +(![null, undefined].includes(values?.premium) && +values?.premium >= 0
      ? +values?.premium
      : values?.auction?.buyer_premium) || 0
  );
};

// to calculate final amount for each type of document line in invoicing
export const calculateTotalForDocumentLine = (data, type, doNotApplyVat, documentType) => {
  let total = 0;

  if (documentType === SALES_NOTE) {
    if (type === "expense") {
      total = data.value;
      if (data?.vat && !doNotApplyVat) total += (total * data?.vat) / 100;
    } else {
      total = data.value * data.qty;
      if (data?.vat && !doNotApplyVat && ["product", "service"].includes(type)) total += (total * data?.vat) / 100;

      if (data?.premium) {
        let SP = (total * data?.premium) / 100;

        // vat on SP
        if (!doNotApplyVat && data?.vat) SP += (SP * data?.vat) / 100;

        total -= SP;
      }
      if (data?.insurance) {
        total -= data?.insurance;
      }
    }
  } else {
    if (type === "product" || type === "service") {
      total = data.value * data.qty;
      if (data?.discount) {
        if (data.discount_unit === "%") total -= (total * data.discount) / 100;
        else total -= data.discount;
      }
      if (!doNotApplyVat && data?.vat) total += (total * data.vat) / 100;
    } else if (type === "lot") {
      total = data.value * data.qty;

      if (data?.premium) {
        let BP = (total * data?.premium) / 100;

        // discount on BP
        if (data?.discount) {
          if (data.discount_unit === "%") BP -= (BP * data.discount) / 100;
          else BP -= data.discount;
        }

        // vat on BP
        if (data?.vat && !doNotApplyVat) {
          BP += (BP * data?.vat) / 100;
        }
        total += BP;
      }
    }
  }

  return roundTo(total);
};

// to calculate final amount for the doucment lines(invoicing)
export const calculateLineFinalAmount = (itemValue, data) => {
  let total = 0;
  if (itemValue) {
    total = itemValue * (data.quantity || 1);

    // calculate discount
    if (data?.discount) {
      if (data?.discount_unit === "%") {
        total -= (total * data.discount) / 100;
      } else if (data?.discount_unit === " €") {
        total -= data?.discount;
      }
    }

    // calculate vat
    if (data?.vat?.rate) {
      total += (total * (data?.vat?.rate || 0)) / 100;
    }
  }
  return roundTo(total);
};

// calculate total amount for document
export const calculateTotalAmountForDocument = (data, documentType) => {
  let total = 0;
  if (documentType === RECEIPT_DOCUMENT) {
    data?.forEach((item) => {
      total += Number(item?.total_to_settle);
    });
  } else {
    data?.forEach((item) => {
      total += Number(item?.amount);
    });
  }
  return roundTo(total);
};

// check permissions by user role/s ["AD","TR"]
export const checkPermissionByRoles = (userRoles) => {
  return userRoles.includes(store?.getState()?.User?.userData?.permission?.code);
};

// get item -> artist, title, description
export const getItemCol = (row) => {
  let str = "";
  if (i18next?.resolvedLanguage === "pt") {
    if (row?.author?.id) str = str.concat(`${row?.author?.description}, `);
    str = row?.title_pt ? str.concat(`${row?.title_pt}`) : str.concat("");
    str = row?.description_pt ? str.concat(`, ${row?.description_pt}`) : str.concat("");
  } else {
    if (row?.author?.id) {
      if (row?.title_en || row?.description_en) str = str.concat(`${row?.author?.description}, `);
      else {
        str = str.concat(`${row?.author?.description}`);
      }
    }
    str = row?.title_en
      ? row?.description_en
        ? str.concat(`${row?.title_en}, `)
        : str.concat(`${row?.title_en}`)
      : str.concat("");
    str = row?.description_en ? str.concat(`${row?.description_en}`) : str.concat("");
  }
  return str || "-";
};

// generate formatted document lines data from contract items.
export const generateDocumentLinesFromContractItems = (items, expenses, defaultPremium, isSalesNote) => {
  const DEFAULT_VAT = store.getState().common?.vats?.default;
  let documentLines = [];

  // contract items
  documentLines = items?.map((lot) => {
    const item = {};
    const itemPremium = isSalesNote ? +lot?.premium || +defaultPremium || 0 : 0;
    const insurance = calculateInsuranceForLots(lot?.auction_lots?.sale_value, 1, DEFAULT_VAT?.rate, false);
    const finalAmount = calculateTotalForDocumentLine(
      {
        qty: 1,
        value: lot?.auction_lots?.sale_value || 0,
        discount: 0,
        insurance: insurance,
        vat: DEFAULT_VAT?.rate,
      },
      "lot",
      false
    );

    item.type_of_product = "lot";
    item.contract = lot?.contract?.id;
    item.contract_item = lot?.id;
    item.quantity = 1;
    item.vat = DEFAULT_VAT?.id;
    item.amount = finalAmount;

    const itemData = {};
    itemData.type_of_product = "lot";
    itemData.positionInContract = lot?.contract_item_no;
    itemData.description = getLotDescriptionForInvoice({
      contract_item: { ...lot },
      lot_no: lot?.auction_lots?.lot_no,
      lot_alpha: lot?.auction_lots?.lot_alpha,
    });
    itemData.quantity = 1;
    itemData.amount = lot?.auction_lots?.sale_value || 0;
    itemData.discount = 0;
    itemData.vat = DEFAULT_VAT?.rate;
    itemData.insurance = insurance;
    itemData.final_amount = finalAmount;
    itemData.premium = itemPremium;
    item.itemData = itemData;

    return { ...item };
  });

  // expenses
  if (expenses?.length > 0) {
    expenses?.forEach((item) => {
      const itemPremium = isSalesNote ? +item?.premium || +defaultPremium || 0 : 0;
      let finalAmount = item?.value;
      if (DEFAULT_VAT) finalAmount += (item?.value * DEFAULT_VAT?.rate) / 100;

      documentLines.push({
        type_of_product: "expense",
        quantity: 1,
        expense: item?.id,
        vat: DEFAULT_VAT?.id,
        amount: finalAmount,
        itemData: {
          type_of_product: "expense",
          description: getExpenseDescription(item),
          quantity: 1,
          vat: DEFAULT_VAT?.rate || 0,
          amount: item?.value || 0,
          final_amount: finalAmount,
          premium: itemPremium,
        },
      });
    });
  }

  return documentLines;
};

export const getPremiumByPaddleNumber = (paddleNumber) => {
  if (!+paddleNumber) return 0;
  const PREMIUM_PERCENTAGES = {
    2: 23, // Represents 200-299
    3: 23, // Represents 300-399
    4: 21, // Represents 400-499
  };

  const paddleNumberRangeKey = parseInt(Math.floor(paddleNumber / 100));
  const premiumPercentage = PREMIUM_PERCENTAGES[paddleNumberRangeKey];

  return premiumPercentage || 0;
};

// generate formatted document lines data from contract items.
export const generateDocumentLinesFromLots = (items, auctionData, paddleNumber) => {
  const DEFAULT_VAT = store.getState().common.vats.default;
  // if paddle number is not available then use buyer premium
  const buyerPremium = getPremiumByPaddleNumber(paddleNumber) || auctionData?.buyer_premium;
  return items?.map((lot) => {
    const item = {};
    const finalAmount = calculateTotalForDocumentLine(
      {
        qty: 1,
        value: lot?.sale_value || 0,
        discount: 0,
        premium: buyerPremium,
        vat: DEFAULT_VAT?.rate,
      },
      "lot",
      false
    );

    item.type_of_product = "lot";
    item.auction = lot?.auction;
    item.paddle = lot?.paddle?.id;
    item.auction_lot = lot?.id;
    item.vat = DEFAULT_VAT?.id;
    item.premium = buyerPremium;
    item.quantity = 1;
    item.amount = finalAmount;
    item.tax_exemption_code = lot?.tax_exemption_code || null;

    const itemData = {};
    itemData.type_of_product = "lot";
    itemData.positionInContract =
      lot?.auction_lot?.contract_item?.contract_item_no || lot?.contract_item?.contract_item_no;
    itemData.description = getLotDescriptionForInvoice(lot);
    itemData.quantity = 1;
    itemData.amount = lot?.sale_value || 0;
    itemData.premium = buyerPremium;
    itemData.discount = 0;
    itemData.vat = DEFAULT_VAT?.rate;
    itemData.final_amount = finalAmount;
    item.itemData = itemData;

    return { ...item };
  });
};

// opne file url in new tab for preview type:preview/download , target for preview
export const openFileURL = (fileUrl, type, target) => {
  if (type === "download") {
    let link = document.createElement("a");
    link.target = "_blank";
    link.href = fileUrl;
    link.download = true;
    link.click();
  } else if (type === "preview") {
    // to preview file
    let link = document.createElement("a");
    link.target = "_blank";
    link.href = fileUrl;
    link.download = true;
    link.click();
  }
};

// get customer option label
export const getCustomerOptionLabel = (customer, seperator = " - ", isForCustomer = false) => {
  let label = "";
  if (customer?.customer_type === "company") {
    label = `${customer?.customer_no}${seperator}${customer?.company_name}`;
  } else {
    const fullName = customer?.surname ? `${customer?.name} ${customer?.surname}` : customer?.name;
    label = `${customer?.customer_no}${seperator}${fullName}`;
  }
  const mainCustomerName = customer?.parent_customer?.name;
  const mainCustomerSurname = customer?.parent_customer?.surname;
  if (isForCustomer && (mainCustomerName || mainCustomerSurname)) {
    return `${label} (${mainCustomerName || ""} ${mainCustomerSurname || ""})`;
  }
  return label;
};

export const getOnlyCustomerName = (customer) => {
  if (customer?.customer_type === "company") {
    return customer?.company_name || "-";
  }
  const fullName = customer?.surname ? `${customer?.name} ${customer?.surname}` : customer?.name;
  return fullName || "-";
};

// get client vat or company vat
export const getCustomerVat = (customer) => {
  if (customer?.customer_type === "company") {
    return customer?.company_vat || "-";
  }
  return customer?.client_vat || "-";
};

// get contract option label
export const getContractOptionLabel = (contract, seperator = " - ") => {
  return `${contract?.contract_no}${seperator}${getOnlyCustomerName(contract?.customer)}`;
};
// get paddle option label
export const getPaddleOptionLabel = (paddle, seperator = " - ") => {
  return `${paddle?.paddle_no}${seperator}${getOnlyCustomerName(paddle?.customer)}`;
};

// get credit notes items data
export const getCreditNoteItemsData = (data) => {
  return data?.map((item) => ({
    ...item,
    item_title: item?.auction_lot?.id
      ? getColumnDetail(item?.auction_lot?.contract_item?.title_en, item?.auction_lot?.contract_item?.title_pt)
      : item?.contract_item?.id
      ? getColumnDetail(item?.contract_item?.title_en, item?.contract_item?.title_pt)
      : item?.product?.id
      ? item?.product?.product_name
      : "",
    item_description: item?.auction_lot?.id
      ? getColumnDetail(
          item?.auction_lot?.contract_item?.description_en,
          item?.auction_lot?.contract_item?.description_pt
        )
      : item?.contract_item?.id
      ? getColumnDetail(item?.contract_item?.description_en, item?.contract_item?.description_pt)
      : "",
  }));
};

// to add seperator in between nullable keys
export const seperateDetails = (data, seperator) => {
  const info = [];
  data.forEach((item) => {
    if (item) {
      info.push(item);
    }
  });
  return info.length > 0 ? info.join(seperator) : "-";
};

// to get the list of Debit notes id
export const getListOfDebitNoteIds = (documentLines) => {
  const ids = [];
  documentLines.forEach((line) => {
    if (line?.type_of_product === "debit_note") {
      ids.push(line?.invoice_id);
    }
  });
  return ids;
};

// to get the list of auction lots id,
export const getListOfItemsIds = (documentLines, type) => {
  if (type === "expense") {
    return documentLines?.filter((item) => item?.expense)?.map((item) => item?.expense);
  }
  if (type === "auction") {
    const selectedIds = { contract_items: [], auction_lots: [], products: [] };
    documentLines.forEach((lineItem) => {
      // contract item
      if (lineItem?.contract_item) {
        selectedIds.contract_items.push(lineItem?.contract_item);
      }
      // auction lot
      if (lineItem?.auction_lot) {
        selectedIds.auction_lots.push(lineItem?.auction_lot);
      }
    });
    return selectedIds;
  } else if (type === "contract") {
    return documentLines?.filter((item) => item?.contract_item)?.map((item) => item?.contract_item);
  } else if (type === "invoice") {
    return documentLines?.filter((item) => item?.invoice_item_id)?.map((item) => item?.invoice_item_id);
  } else if (type === "transport") {
    const selectedIds = { contract_items: [], auction_lots: [], products: [] };
    documentLines.forEach((lineItem) => {
      // contract item
      if (lineItem?.contract_item) {
        selectedIds.contract_items.push(lineItem?.contract_item);
      }
      // auction lot
      if (lineItem?.auction_lot) {
        selectedIds.auction_lots.push(lineItem?.auction_lot);
      }
      // product / service
      if (lineItem?.product) {
        selectedIds.products.push(lineItem?.product);
      }
    });
    return selectedIds;
  }
};

// to get category data for contract items.
export const getCategoryForContractItems = (categories, isEnglish) => {
  let data = [];
  if (categories?.length > 0) {
    categories?.forEach((category) => {
      if (isEnglish) {
        data.push(category?.category_name_en);
      } else {
        data.push(category?.category_name_pt);
      }
    });
  }
  return data?.length > 0 ? data.join(", ") : "";
};
// to get the title for contract items excel
export const getTitleForItems = (item, isEnglish) => {
  let data = [];
  if (item?.author?.id) {
    data.push(item?.author?.description);
  }
  if (isEnglish) {
    if (item?.title_en) data.push(item?.title_en);
  } else {
    if (item?.title_pt) data.push(item?.title_pt);
  }

  return data?.length > 0 ? data.join(", ") : "-";
};

// to get the list of status for contract items
export const getItemsStatuses = (item) => {
  let statuses = [];

  item?.lot_tags_dict?.forEach((tag) => {
    const tagValue = getColumnDetail(tag?.tag_label, tag?.tag_label_pt);
    if (tagValue) {
      statuses.push(tagValue);
    }
  });

  return statuses?.length > 0 ? statuses?.join("\n") : "-";
};

// to generate excel data for conctract items.
export const generateContractExcelData = (data, isEnglish) => {
  if (isEnglish) {
    return data?.map((item) => {
      return {
        "No.": item?.contract_item_no || "-",
        Artist: item?.author?.description || "-",
        Title: item?.title_en || "-",
        Description: item?.description_en || "-",
        Category: getCategoryForContractItems(item?.categories, true),
        Period: item?.period?.title_en || "-",
        Metal: getTypeFromValue(METAL_OPTIONS, item?.metal)?.label || "-",
        Dimensions: item?.dimensions_en || "-",
        Weight: item?.weight_en || "-",
        Literature: item?.literature_en || "-",
        Provenance: item?.provenance_en || "-",
        Exhibitions: item?.exhibitions_en || "-",
        Notes: item?.notes_en || "-",
        Text: item?.text_en || "-",
        "Minimum Estimate": item?.min_estimate || "-",
        "Maximum Estimate": item?.max_estimate || "-",
        "Reserve Price": item?.reserve_price || "-",
        "Sale Value": item?.auction_lots?.sale_value || "-",
        "Auction No": item?.auction?.auction_no || "-",
        "Lot No.": item?.auction_lots?.lot_no ? item?.auction_lots?.lot_no + item?.auction_lots?.lot_alpha : "-",
        "Internal Observations": item?.internal_observations || "-",
      };
    });
  } else {
    return data?.map((item) => {
      return {
        Nº: item?.contract_item_no || "-",
        Artista: item?.author?.description || "-",
        Título: item?.title_pt || "-",
        Descrição: item?.description_pt || "-",
        Categoria: getCategoryForContractItems(item?.categories, false),
        Período: item?.period?.title_pt || "-",
        Metal: getTypeFromValue(METAL_OPTIONS, item?.metal)?.label || "-",
        Dimensões: item?.dimensions_pt || "-",
        Peso: item?.weight_pt || "-",
        Bibliografia: item?.literature_pt || "-",
        Proveniência: item?.provenance_pt || "-",
        Exposições: item?.exhibitions_pt || "-",
        Notas: item?.notes_pt || "-",
        Texto: item?.text_pt || "-",
        "Estimativa mínima": item?.min_estimate || "-",
        "Estimativa máxima": item?.max_estimate || "-",
        "Preço de Reserva": item?.reserve_price || "-",
        "Valor de Venda": item?.auction_lots?.sale_value || "-",
        "Leilão Nº": item?.auction?.auction_no || "-",
        "Lote Nº": item?.auction_lots?.lot_no ? item?.auction_lots?.lot_no + item?.auction_lots?.lot_alpha : "-",
        "Observações Internas": item?.internal_observations || "-",
      };
    });
  }
};

// to generate excel data for conctract items.
export const generateContractItemsExcelData = (data, isEnglish) => {
  if (isEnglish) {
    return data?.map((item) => {
      return {
        "No.": item?.contract_item_no || "-",
        Title: getTitleForItems(item, true),
        Description: item?.description_en || "-",
        Category: getCategoryForContractItems(item?.categories, true),
        Period: item?.period?.title_en || "-",
        Metal: "-",
        Dimensions: item?.dimensions_en || "-",
        Weight: item?.weight_en || "-",
        Notes: item?.notes_en || "-",
        "Internal Observations": item?.internal_observations || "-",
        Seller: item?.contract?.customer ? getOnlyCustomerName(item?.contract?.customer) : "-",
        "Minimum Estimate": item?.min_estimate || "-",
        "Maximum Estimate": item?.max_estimate || "-",
        "Auction No": item?.auction?.auction_no || "-",
        "Lot No.": item?.auction_lots?.lot_no ? item?.auction_lots?.lot_no + item?.auction_lots?.lot_alpha : "-",
        Paddle: item?.auction_lots?.paddle?.id ? item?.auction_lots?.paddle?.paddle_no : "-",
        "Sale Value": item?.auction_lots?.sale_value || "-",
        Buyer:
          item?.auction_lots?.paddle?.id && item?.auction_lots?.paddle?.customer?.id
            ? getOnlyCustomerName(item?.auction_lots?.paddle?.customer)
            : "-",
        "Buyer's address": item?.auction_lots?.paddle?.customer?.id
          ? getCustomerAddress(
              item?.auction_lots?.paddle?.customer?.addresses,
              item?.auction_lots?.paddle?.customer?.customer_type === "company" ? "company" : "client"
            )
          : "-",
        Status: getItemsStatuses(item),
      };
    });
  } else {
    return data?.map((item) => {
      return {
        Nº: item?.contract_item_no || "-",
        Título: getTitleForItems(item, false),
        Descrição: item?.description_pt || "-",
        Categoria: getCategoryForContractItems(item?.categories, false),
        Período: item?.period?.title_pt || "-",
        Metal: "-",
        Dimensões: item?.dimensions_pt || "-",
        Peso: item?.weight_pt || "-",
        Notas: item?.notes_pt || "-",
        "Observações Internas": item?.internal_observations || "-",
        Vendedor: item?.contract?.customer ? getOnlyCustomerName(item?.contract?.customer) : "-",
        "Estimativa mínima": item?.min_estimate || "-",
        "Estimativa máxima": item?.max_estimate || "-",
        "Leilão Nº": item?.auction?.auction_no || "-",
        "Lote Nº": item?.auction_lots?.lot_no ? item?.auction_lots?.lot_no + item?.auction_lots?.lot_alpha : "-",
        Raquete: item?.auction_lots?.paddle?.id ? item?.auction_lots?.paddle?.paddle_no : "-",
        "Valor de Venda": item?.auction_lots?.sale_value || "-",
        Comprador:
          item?.auction_lots?.paddle?.id && item?.auction_lots?.paddle?.customer?.id
            ? getOnlyCustomerName(item?.auction_lots?.paddle?.customer)
            : "-",
        "Morada do comprador": item?.auction_lots?.paddle?.customer?.id
          ? getCustomerAddress(
              item?.auction_lots?.paddle?.customer?.addresses,
              item?.auction_lots?.paddle?.customer?.customer_type === "company" ? "company" : "client"
            )
          : "-",
        Estado: getItemsStatuses(item),
      };
    });
  }
};

// to get the formated website information for each type of page
export const getFormattedDataForWebsitePages = (data, type) => {
  switch (type) {
    case HOME_PAGE: {
      const formattedData = { carousel: [], block: [], banner: [] };
      data.forEach((item) => {
        formattedData?.[item?.file_type]?.push({
          ...item,
          file: item?.file || "",
          title: item?.field1 || "",
          subtitle: item?.field2 || "",
          link: item?.link || "",
          order_no: item?.order_no || "",
        });
      });
      return formattedData;
    }
    case SERVICES_APPRAISALS: {
      const formattedData = { banner: [], meta_info: [] };
      data.forEach((item) => {
        if (item?.file_type === "meta_info") {
          formattedData.meta_info = [
            {
              id: item?.id,
              informal_para_1: item?.meta?.informal_para_1 ? getEditorState(item?.meta?.informal_para_1) : "",
            },
            {
              id: item?.id,
              informal_para_2: item?.meta?.informal_para_2 ? getEditorState(item?.meta?.informal_para_2) : "",
            },
            {
              id: item?.id,
              informal_para_3: item?.meta?.informal_para_3 ? getEditorState(item?.meta?.informal_para_3) : "",
            },
            {
              id: item?.id,
              formal_para_1: item?.meta?.formal_para_1 ? getEditorState(item?.meta?.formal_para_1) : "",
            },
          ];
        } else {
          formattedData?.[item?.file_type]?.push({
            ...item,
            file: item?.file || "",
            title: item?.field1 || "",
            subtitle: item?.field2 || "",
            link: item?.link || "",
          });
        }
      });
      return formattedData;
    }
    case SERVICES_EXPERTIES: {
      const formattedData = { banner: [], meta_info: [] };
      data.forEach((item) => {
        if (item?.file_type === "meta_info") {
          formattedData.meta_info = [
            {
              id: item?.id,
              information_1: item?.meta?.information_1 ? getEditorState(item?.meta?.information_1) : "",
            },
          ];
        } else {
          formattedData?.[item?.file_type]?.push({
            ...item,
            file: item?.file || "",
            title: item?.field1 || "",
            subtitle: item?.field2 || "",
            link: item?.link || "",
          });
        }
      });
      return formattedData;
    }
    case SERVICES_CONSULTANCY: {
      const formattedData = { banner: [], meta_info: [] };
      data.forEach((item) => {
        if (item?.file_type === "meta_info") {
          formattedData.meta_info = [
            {
              id: item?.id,
              information_1: item?.meta?.information_1 ? getEditorState(item?.meta?.information_1) : "",
            },
          ];
        } else {
          formattedData?.[item?.file_type]?.push({
            ...item,
            file: item?.file || "",
            title: item?.field1 || "",
            subtitle: item?.field2 || "",
            link: item?.link || "",
          });
        }
      });
      return formattedData;
    }
    case PRIVATE_SALES: {
      const formattedData = { banner: [], meta_info: [] };
      data.forEach((item) => {
        if (item?.file_type === "meta_info") {
          formattedData.meta_info = [
            {
              id: item?.id,
              information_1: item?.meta?.information_1 ? getEditorState(item?.meta?.information_1) : "",
            },
          ];
        } else {
          formattedData?.[item?.file_type]?.push({
            ...item,
            file: item?.file || "",
            title: item?.field1 || "",
            subtitle: item?.field2 || "",
            link: item?.link || "",
          });
        }
      });
      return formattedData;
    }
    case BUYING_CONTENT: {
      const formattedData = { banner: [], meta_info: [] };
      data.forEach((item) => {
        if (item?.file_type === "meta_info") {
          formattedData.meta_info = [
            {
              id: item?.id,
              information_1: item?.meta?.information_1 ? getEditorState(item?.meta?.information_1) : "",
            },
            {
              id: item?.id,
              information_2: item?.meta?.information_2 ? getEditorState(item?.meta?.information_2) : "",
            },
            {
              id: item?.id,
              information_3: item?.meta?.information_3 ? getEditorState(item?.meta?.information_3) : "",
            },
          ];
        } else {
          formattedData?.[item?.file_type]?.push({
            ...item,
            file: item?.file || "",
            title: item?.field1 || "",
            subtitle: item?.field2 || "",
            link: item?.link || "",
          });
        }
      });
      return formattedData;
    }
    case SELLING_CONTENT: {
      const formattedData = { banner: [], meta_info: [] };
      data.forEach((item) => {
        if (item?.file_type === "meta_info") {
          formattedData.meta_info = [
            {
              id: item?.id,
              information_1: item?.meta?.information_1 ? getEditorState(item?.meta?.information_1) : "",
            },
            {
              id: item?.id,
              information_2: item?.meta?.information_2 ? getEditorState(item?.meta?.information_2) : "",
            },
            {
              id: item?.id,
              information_3: item?.meta?.information_3 ? getEditorState(item?.meta?.information_3) : "",
            },
          ];
        } else {
          formattedData?.[item?.file_type]?.push({
            ...item,
            file: item?.file || "",
            title: item?.field1 || "",
            subtitle: item?.field2 || "",
            link: item?.link || "",
          });
        }
      });
      return formattedData;
    }
    case WHO_WE_ARE_CONTENT: {
      const formattedData = { carousel: [], meta_info: [] };
      data.forEach((item) => {
        if (item?.file_type === "meta_info") {
          formattedData.meta_info = [
            {
              id: item?.id,
              information_1: item?.meta?.information_1 ? getEditorState(item?.meta?.information_1) : "",
            },
          ];
        } else {
          formattedData?.[item?.file_type]?.push({
            ...item,
            file: item?.file || "",
            title: item?.field1 || "",
            subtitle: item?.field2 || "",
            link: item?.link || "",
          });
        }
      });
      return formattedData;
    }
    case CONTACT_US_CONTENT: {
      const formattedData = {
        banner: [],
        meta_info: [],
        departments: [],
        teams: [],
      };
      data.forEach((item) => {
        if (item?.file_type === "meta_info") {
          formattedData.meta_info = [
            {
              id: item?.id,
              information_1: item?.meta?.information_1 ? getEditorState(item?.meta?.information_1) : "",
            },
          ];
        } else if (item?.file_type === "departments") {
          formattedData?.[item?.file_type]?.push({
            ...item,
            name: item?.field1 || "",
            email: item?.field2 || "",
          });
        } else if (item?.file_type === "teams") {
          formattedData?.[item?.file_type]?.push({
            ...item,
            position: item?.position?.id || "",
            name: item?.field1 || "",
            email: item?.field2 || "",
          });
        } else {
          formattedData?.[item?.file_type]?.push({
            ...item,
            file: item?.file || "",
            title: item?.field1 || "",
            subtitle: item?.field2 || "",
            link: item?.link || "",
          });
        }
      });
      return formattedData;
    }
    case OTHER_PAGE:
      return data;
    default:
      return data;
  }
};

// string lowercase comparison
export const equalsIgnoreCase = (str1, str2) => {
  return str1?.toLowerCase() === str2?.toLowerCase();
};

// to upload editor state
export const uploadEditorState = (meta) => {
  return JSON.stringify(convertToRaw(meta?.getCurrentContent()));
};

// to get editor state
export const getEditorState = (meta) => {
  return EditorState.createWithContent(convertFromRaw(JSON.parse(meta)));
};

// to get the extension of the file url
export const getFileURLExtension = (fileURL) => {
  const splittedURL = fileURL.split(".");
  return "." + splittedURL?.[splittedURL?.length - 1];
};

// onkey press for the form components
export const onKeyPressForm = (e) => {
  if (e.which === 13 && e.key === "Enter" && e.target.tagName !== "TEXTAREA") {
    e.preventDefault();
  }
};

// check if link is not provided in pages data
export const checkLinksInPagesData = (values) => {
  let isLinkEmpty = false;
  const keys = ["banner", "block", "carousel"];
  keys?.forEach((key) => {
    values?.[key]?.forEach((item) => {
      if (!item?.link) {
        isLinkEmpty = true;
      }
    });
  });

  return isLinkEmpty;
};

// check image file type validation
export const checkImageFileValidation = (fileName) => {
  return IMAGE_EXTENSIONS.includes("." + fileName?.split(".")?.pop());
};

// check video file type validation
export const checkVideoFileValidation = (fileName) => {
  return VIDEO_EXTENSIONS.includes("." + fileName?.split(".")?.pop());
};

// check video file type validation
export const checkPDFFileValidation = (fileName) => {
  return PDF_EXTENSIONS.includes("." + fileName?.split(".")?.pop());
};

// convert URL to file
export const urlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

// currency formater
export const currencyFormatter = (amount, locale = navigator.language) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
  }).format(amount);
};

// get list of object by matching with value array
export const getObjectsByValues = (listOfObjects, valuesArray, valueKey) => {
  return listOfObjects?.filter((obj) => valuesArray?.includes(obj?.[valueKey]));
};

// sort by alphabatic order array of objects with specified key name
export const sortByObjectsByName = (array = [], key) => {
  if (array?.length > 0) {
    return array.sort((a, b) => a[key].localeCompare(b[key]));
  }
  return [];
};

//filter out options according to document type
export const filteredTypeOfProduct = (documentType) => {
  if (documentType === INVOICE_DOCUMENT || documentType === PROFORMA_INVOICE_DOCUMENT) {
    return TYPE_OF_PRODUCTS.filter((option) => option.value === "lot");
  } else if (documentType === NORMAL_INVOICE || documentType === NORMAL_PROFORMA) {
    return TYPE_OF_PRODUCTS?.filter((type) => !["lot", "expense", "debit_note"]?.includes(type?.value));
  } else if (documentType === PROFORMA_NORMAL || documentType === FATURA_INVOICE) {
    return TYPE_OF_PRODUCTS?.filter((type) => !["lot", "expense", "debit_note"]?.includes(type?.value));
  } else {
    return TYPE_OF_PRODUCTS?.filter((type) => !["expense", "debit_note"]?.includes(type?.value));
  }
};

//SET VAT OPTION AT PROFORMA INVOICE DOCUMENT LINE MODAL
export const getFilteredVATOption = (doNotApplyVAT, VAT_OPTIONS, isLot) => {
  if (doNotApplyVAT || isLot === "lot") {
    return VAT_OPTIONS.filter((option) => option.rate === 0);
  } else if (isLot === "lot") {
    return VAT_OPTIONS.filter((option) => option.rate === 23);
  } else {
    return VAT_OPTIONS.filter((option) => option.rate !== 0);
  }
};

//set filter for reference document
export const getFilterReferenceDocumentFilter = (documentType) => {
  let filter = "";

  switch (documentType) {
    //invoice normal
    case NORMAL_INVOICE:
      filter = [PROFORMA_NORMAL];
      break;
    // proforma normal
    case NORMAL_PROFORMA:
      filter = [];
      break;
    //invoice buyer
    case INVOICE_DOCUMENT:
      filter = [PROFORMA_INVOICE_DOCUMENT];
      break;
    //invoice buyer
    case SALES_NOTE:
      filter = [SALES_NOTE_PROFORMA];
      break;
    //proforma buyer
    case PROFORMA_INVOICE_DOCUMENT:
      filter = [];
      break;
    //proforma seller
    case SALES_NOTE_PROFORMA:
      filter = [];
      break;
    //tranport document
    case TRANSPORT_DOCUMENT:
      filter = [INVOICE_DOCUMENT, RETURN_GUIDE_DOCUMENT];
      break;
    //credit note
    case CREDIT_NOTE:
      filter = [NORMAL_INVOICE, SALES_NOTE];
      break;
    //debit note
    case DEBIT_NOTE:
      filter = [NORMAL_INVOICE, SALES_NOTE];
      break;
    //credit note lot
    case CREDIT_NOTE_LOT:
      filter = [INVOICE_DOCUMENT];
      break;
    //debit note lot
    case DEBIT_NOTE_LOT:
      filter = [INVOICE_DOCUMENT];
      break;
    //receipt
    case RECEIPT_DOCUMENT:
      filter = [NORMAL_INVOICE, INVOICE_DOCUMENT, DEBIT_NOTE, DEBIT_NOTE_LOT, SALES_NOTE];
      break;
    default:
      filter = [];
      break;
  }

  return filter;
};

// Formatting the amounts based on the region (PT/EN)
export const formatAmount = (amount) => roundTo(amount).toLocaleString(i18next.resolvedLanguage);

export const getReferenceDocumentLabel = (referenceDocument) => {
  const labels = [];
  if (referenceDocument?.document_type?.description !== RETURN_GUIDE_DOCUMENT && referenceDocument?.phc_document_no) {
    labels.push(referenceDocument?.phc_document_no);
  }
  labels.push(referenceDocument?.document_no, referenceDocument?.date);

  return labels.join(" - ");
};

export const scrollToFirstFormikError = (errors = {}) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length > 0) {
    document.querySelector(`label[for='${errorKeys?.[0]}']`)?.scrollIntoViewIfNeeded?.();
  }
};

export const kebabCase = (str) =>
  str
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

export const objectifyBy = (arr, key) => {
  const obj = {};

  for (const item of arr) {
    obj[item[key]] = item;
  }

  return obj;
};

export const uniqBy = (arr, key) => Object.values(objectifyBy(arr, key));
