import React, { useCallback, useEffect, useRef, useState } from "react";

import i18next from "i18next";
import { omit } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { components } from "react-select";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router-dom";
import { Badge, Button, Card, Input, Label } from "reactstrap";

import UpdateLot from "./UpdateLot";
import LotPopover from "./LotPopover";
import AddLotModal from "./AddLotModal";
import Loader from "../../common/Loader";
import BulkUpdateLot from "./BulkUpdateLot";
import DeleteModal from "../../common/DeleteModal";
import CustomButton from "../../common/CustomButton";
import CustomTagChip from "../../common/CustomTagChip";
import API from "../../../helpers/api/api_interceptor";
import CustomTextLink from "../../common/CustomTextLink";
import { getTags } from "../../../store/lots-tags/actions";
import NoRecords from "../../../components/common/NoRecords";
import ImportExcelModal from "../contracts/ImportExcelModal";
import CustomColumnPhoto from "../../common/CustomColumnPhoto";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import CustomLinkWrapper from "../../common/CustomLinkWrapper";
import { getCategories } from "../../../store/categories/actions";
import { DEFAULT_ROW_PER_PAGE } from "../../../helpers/constants";
import { getCountriesOptions } from "../../../store/common/actions";
import CustomTooltipWrapper from "../../common/CustomTooltipWrapper";
import CustomDropdownButton from "../../common/CustomDropdownButton";
import { getShippingOptions } from "../../../store/customers/actions";
import CustomAddButton from "../../../components/common/CustomAddButton";
import CustomSearchInput from "../../../components/common/CustomSearchInput";
import CustomColumnHeader from "../../../components/common/CustomColumnHeader";
import { generateLotExcelData, getExportLotFileName } from "../../../helpers/lotExportDataGenerator";
import { SELECT_ALL_OPTION, TAG_BUREAUCRACY_OPTIONS } from "../../../helpers/constants/selectOptions";
import {
  addLots,
  bulkUpdateLot,
  deleteLot,
  exportCataloguesShipping,
  getLotLists,
  updateLot,
  uploadLotsExcel,
  uploadTranslatedLotsExcel,
} from "../../../store/auctions/lotActions";
import {
  checkSelectAllSelected,
  exportExcelWithTitleRow,
  exportToexcel,
  formatAmount,
  generateCataloguesShippingExportData,
  generateContrastColor,
  getColumnDetail,
  getConcatedStringFromArray,
  getCustomerAddress,
  getIdFromArray,
  getLabelValueArray,
  getListOfTagsBureaucracy,
  getOnlyCustomerName,
  getValuesFromArray,
} from "../../../helpers/utils";
import { toast } from "react-toastify";

export const INITIAL_GET_LOTS_PAYLOAD = {
  search: "",
  page: 1,
  limit: DEFAULT_ROW_PER_PAGE,
  sort: "lot_no",
  filter: {},
  exclude: {},
};

const Lots = (props) => {
  const { id } = useParams();
  const popoverRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const auctionData = useSelector((state) => state?.Auctions?.auction);
  const lotsData = useSelector((state) => state?.Auctions?.lots?.data);
  const totalLots = useSelector((state) => state?.Auctions?.lots?.total);
  const allLotIds = useSelector((state) => state?.Auctions?.lots?.all_ids);

  const CATEGORIES_OPTIONS = getLabelValueArray(
    useSelector((state) => state?.Categories?.data),
    "id",
    i18next.resolvedLanguage === "en" ? "category_name_en" : "category_name_pt"
  );

  const countriesOptions = getLabelValueArray(
    useSelector((state) => state?.common?.countriesOptions?.data) || [],
    "id",
    "code"
  );

  const auctionLotTagOptions = getLabelValueArray(
    useSelector((state) => state?.LotsTags?.data) || [],
    "id",
    getColumnDetail("tag_label", "tag_label_pt")
  );

  // loader
  const [tableLoader, setTableLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [bulkDeleteLoader, setBulkDeleteLoader] = useState(false);
  const [lotSaveLoader, setLotSaveLoader] = useState(false);
  const [cellUpdateLoader, setCellUpdateLoader] = useState(false);
  const [lotUpdateLoader, setLotUpdateLoader] = useState(false);
  const [bulkLotUpdateLoader, setBulkLotUpdateLoader] = useState(false);
  const [exportBtnLoader, setExportBtnLoader] = useState(false);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const [isImportLotsModalOpen, setIsImportLotsModalOpen] = useState(false);
  const [importLotsLoader, setImportLotsLoader] = useState(false);
  const [importType, setImportType] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTags, setSelectedTags] = useState("");
  const [selectedTagsBureaucracy, setSelectedTagsBureaucracy] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [selectedUpdateLot, setSelectedUpdateLot] = useState(null);
  const [sortingOrder, setSortingOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [exclude, setExclude] = useState([]);

  const [lotsPayload, setLotsPayload] = useState({
    ...INITIAL_GET_LOTS_PAYLOAD,
    filter: { ...INITIAL_GET_LOTS_PAYLOAD?.filter, auction: props?.id },
    exclude: { ...INITIAL_GET_LOTS_PAYLOAD?.exclude, id__in: exclude },
  });

  const [categoriesPayload, setCategoriesPayload] = useState({
    page: 1,
    sort: i18next.resolvedLanguage === "en" ? "category_name_en" : "category_name_pt",
  });
  const [tagsLoader, setTagsLoader] = useState(false);
  const [lotsTagsPayload, setLotsTagsPayload] = useState({ sort: getColumnDetail("tag_label", "tag_label_pt") });

  // get countries list, vats and lots tags
  useEffect(() => {
    dispatch(getCountriesOptions({ sort: "name" }));
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "tag" },
      }),
    );
    dispatch(
      getShippingOptions({
        sort: getColumnDetail("name_en", "name_pt"),
        filter: { type: "auction_lot_tag" },
      }),
    );
    dispatch(getCategories(categoriesPayload));
  }, [categoriesPayload, dispatch]);

  // get the list of lots tags
  useEffect(() => {
    setTagsLoader(true);
    dispatch(getTags(lotsTagsPayload)).then(() => {
      setTagsLoader(false);
    });
  }, [lotsTagsPayload, dispatch]);

  // handle import  lots modal open
  const handleImportLotsModalOpen = (importType) => {
    setImportType(importType);
    setIsImportLotsModalOpen(true);
  };

  // handle import  lots modal open
  const handleImportLotsModalClose = () => {
    setIsImportLotsModalOpen(false);
    setImportType();
  };
  // handle import lots
  const handleImportExcel = (values) => {
    if (importType === "lots") {
      handleImportLots(values);
    } else {
      handleImportTranslatedLots(values);
    }
  };
  // to handle import lots file
  const handleImportLots = (values) => {
    setImportLotsLoader(true);
    dispatch(uploadLotsExcel(id, values)).then((res) => {
      if (res) {
        handleGetLotLists(lotsPayload);
        setImportLotsLoader(false);
        handleImportLotsModalClose();
      } else {
        setImportLotsLoader(false);
      }
    });
  };
  // to handle import translated lots file
  const handleImportTranslatedLots = (values) => {
    setImportLotsLoader(true);
    dispatch(uploadTranslatedLotsExcel(id, values)).then((res) => {
      if (res) {
        handleGetLotLists(lotsPayload);
        setImportLotsLoader(false);
        handleImportLotsModalClose();
      } else {
        setImportLotsLoader(false);
      }
    });
  };

  // handle getting lots item
  const handleGetLotLists = useCallback(
    (lotsPayload) => {
      setTableLoader(true);
      dispatch(getLotLists(lotsPayload)).then((res) => {
        setTableLoader(false);
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (props?.id) {
      handleGetLotLists(lotsPayload);
    }
  }, [handleGetLotLists, lotsPayload, props?.id]);

  //   handle delete modal open
  const handleDeleteModalOpen = (itemData) => {
    setSelectedItem(itemData);
    setIsDeleteModalOpen(true);
  };
  //   handle delete modal close
  const handleDeleteModalClose = () => {
    setSelectedItem(null);
    setIsDeleteModalOpen(false);
  };

  //   handle delete item
  const handleDeleteItem = () => {
    if (selectedItem && selectedItem?.id) {
      setDeleteLoader(true);
      dispatch(
        deleteLot({
          filter: {},
          isSelectedAll: false,
          ids: [selectedItem?.id],
          apiType: "auction_lot",
          exclude: {},
        }),
      ).then((res) => {
        setDeleteLoader(false);
        handleDeleteModalClose();
        handleGetLotLists(lotsPayload);
        setSelectedRows((prevState) => [...prevState.filter((item) => item !== selectedItem?.id)]);
      });
    }
  };

  //   handle bulk update tags modal open
  const handleBulkUpdateModalOpen = () => {
    setIsBulkUpdateModalOpen(true);
  };
  //   handle bulk update tags modal close
  const handleBulkUpdateModalClose = () => {
    setIsBulkUpdateModalOpen(false);
  };

  // handle bulk update api call
  const handleBulkUpdateLots = (selectedTagItems) => {
    if (props.id && selectedTagItems.length > 0) {
      // return;
      setBulkLotUpdateLoader(true);
      dispatch(
        bulkUpdateLot({
          ids: selectedRows,
          is_selected_all: false,
          auction_lot_tag: selectedTagItems,
          exclude: lotsPayload?.exclude,
        }),
      )
        .then((res) => {
          if (res) {
            setBulkLotUpdateLoader(false);
            handleBulkUpdateModalClose();
            handleGetLotLists(lotsPayload);
          } else {
            setBulkLotUpdateLoader(false);
          }
        })
        .catch((error) => {
          handleBulkUpdateModalClose();
          setBulkLotUpdateLoader(false);
        });
    }
  };

  //   handle bulk delete modal open
  const handleBulkDeleteModalOpen = () => {
    setIsBulkDeleteModalOpen(true);
  };
  //   handle bulk delete modal close
  const handleBulkDeleteModalClose = () => {
    setIsBulkDeleteModalOpen(false);
  };

  // to handle bulk deleting of the items
  const handleBulkDelete = () => {
    setBulkDeleteLoader(true);
    dispatch(
      deleteLot({
        isSelectedAll: false,
        ids: selectedRows,
        apiType: "auction_lot",
        exclude: isAllSelected ? { id__in: exclude } : [],
      }),
    ).then((res) => {
      handleBulkDeleteModalClose();
      setBulkDeleteLoader(false);
      handleGetLotLists(lotsPayload);
      setIsAllSelected(false);
      setSelectedRows([]);
    });
  };

  useEffect(() => {
  }, [exclude]);
  // handle page change
  const handlePageChange = (value) => {
    setLotsPayload((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  // handle rows per page change
  const handleRowsPerPageChange = (value) => {
    setLotsPayload((prevState) => ({
      ...prevState,
      limit: value,
    }));
  };

  // handle dropdown type filter
  const handleDropdownFilter = (values, filterKey, setOptionTypes) => {
    if (checkSelectAllSelected(values)) {
      setOptionTypes(SELECT_ALL_OPTION);
      setLotsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else {
      if (values.length > 0) {
        setLotsPayload((prevState) => ({
          ...prevState,
          filter: {
            ...prevState.filter,
            [filterKey]: getValuesFromArray(values),
          },
          page: 1,
        }));
      } else {
        setLotsPayload((prevState) => {
          const tempPayload = prevState;
          delete tempPayload.filter[filterKey];
          return { ...tempPayload, page: 1 };
        });
      }
    }
  };

  // handle sorting functionality
  useEffect(() => {
    if (orderBy !== "" && sortingOrder !== "")
      setLotsPayload((prevState) => ({
        ...prevState,
        sort: sortingOrder === "asc" ? orderBy : sortingOrder === "desc" ? `${"-" + orderBy}` : "",
      }));
  }, [orderBy, sortingOrder]);

  const handleAddressFilter = (selectedItem, filterKey) => {
    if (selectedItem && selectedItem.value === "*") {
      setSelectedAddress(SELECT_ALL_OPTION);
      return setLotsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.exclude;
        delete tempPayload.filter[filterKey];
        return { ...tempPayload, page: 1 };
      });
    } else if (selectedItem && selectedItem.value === "portugal") {
      let tempPayload = lotsPayload;
      delete tempPayload.exclude;

      return setLotsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.exclude;
        return {
          ...tempPayload,
          filter: {
            ...prevState.filter,
            [filterKey]: getIdFromArray(countriesOptions, "PT"),
          },
          page: 1,
        };
      });
    } else if (selectedItem && selectedItem.value === "internationals") {
      return setLotsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        return {
          ...tempPayload,
          exclude: {
            [filterKey]: getIdFromArray(countriesOptions, "PT"),
          },
          page: 1,
        };
      });
    } else {
      setLotsPayload((prevState) => {
        const tempPayload = prevState;
        delete tempPayload.filter[filterKey];
        delete tempPayload.exclude;
        return { ...tempPayload, page: 1 };
      });
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} />
          <label className="ms-2">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  const handleSaveLots = (isAllSelected, selectedItems, itemFilter, itemsExclude) => {
    if (props.id) {
      setLotSaveLoader(true);
      dispatch(
        addLots({
          auctionId: props?.id,
          contractIds: isAllSelected ? [] : selectedItems,
          isSelectedAll: isAllSelected,
          filter: itemFilter,
          exclude: isAllSelected ? { id__in: itemsExclude } : [],
        }),
      )
        .then((res) => {
          if (res) {
            handleGetLotLists(lotsPayload);
            handleAddModalClose();
          }
          setLotSaveLoader(false);
        })
        .catch((error) => {
          handleAddModalClose();
          setLotSaveLoader(false);
        });
    }
  };

  // for id updation
  const handleLotNoSave = (values) => {
    if (selectedId) {
      setCellUpdateLoader(true);
      dispatch(
        updateLot(
          {
            lotId: selectedId?.id,
            auctionId: props?.id,
            lot_no: values?.lot_no,
            lot_alpha: values?.lot_suffix,
            delete_lot_no: values?.delete_lot_no || false,
          },
          "lot_no",
        ),
      )
        .then((res) => {
          if (res) {
            handleGetLotLists(lotsPayload);
            setPopoverOpen(false);
          }
          setCellUpdateLoader(false);
        })
        .catch((error) => {
          setPopoverOpen(false);
          setCellUpdateLoader(false);
        });
    }
  };

  const handleExportExcel = (type, generalPJ, jewellaryPJ, ASAEMap) => {
    setExportBtnLoader(true);

    let excludePayload = {};
    let filter = {};
    let lotItemPayload = {};
    if (exclude?.length) {
      excludePayload.id__in = exclude;
    } else {
      if (!isAllSelected && selectedRows?.length) {
        filter.id__in = selectedRows;
      }
    }

    if (generalPJ || jewellaryPJ || ASAEMap) {
      lotItemPayload = {
        general_psp_map: generalPJ,
        jewelry_psp_map: jewellaryPJ,
        asae_map: ASAEMap,
      };
    }
    if (jewellaryPJ || ASAEMap) {
      filter.contract_item__metal__isnull = false;
    }

    API.get(`/auctions/lots`, {
      params: {
        search: lotsPayload?.search,
        filter: {
          ...filter,
          ...lotsPayload?.filter,
          ...lotItemPayload?.filter,
        },
        export_filter: lotItemPayload,
        sort: lotsPayload?.sort,
        exclude: excludePayload,
      },
    })
      .then((res) => {
        if (res?.data?.data?.results?.length === 0) {
          toast.info(props.t("information.noItemsForExport"));
        } else if (res && res.status && res.status === 200) {
          const { widthArr, data } = generateLotExcelData(type, res?.data?.data?.results);
          exportToexcel(data, `${auctionData?.auction_no}_${getExportLotFileName(type)}`, widthArr);
          handleGetLotLists(lotsPayload);
        }
        setExportBtnLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setExportBtnLoader(false);
      });
  };

  // redirect to customer edit page
  const navigateToCustomerEditPage = (row) => {
    if (row?.contract_item?.contract?.customer?.id && !row?.contract_item?.contract?.customer?.parent_id)
      history.push(`/customers/edit/${row?.contract_item?.contract?.customer?.id}`);
    else if (row?.contract_item?.contract?.customer?.parent_id)
      history.push(
        `/customers/${row?.contract_item?.contract?.customer?.parent_id}/edit-person/${row?.contract_item?.contract?.customer?.id}`,
      );
  };

  // navigate to customer edit page
  const navigateToContractItemPage = (row) => {
    history.push(`/contracts/contracts/${row?.contract_item?.contract?.id}/items/edit/${row?.contract_item?.id}`);
  };

  const handleUpdateLotModalOpen = (row) => {
    setSelectedUpdateLot(row);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateLotModalClose = () => {
    setIsUpdateModalOpen(false);
  };

  const handleUpdateLot = (values, formikValues) => {
    if (selectedUpdateLot && selectedUpdateLot?.id) {
      setLotUpdateLoader(true);
      dispatch(
        updateLot(
          {
            lotId: selectedUpdateLot?.id,
            auctionId: props?.id,
            lot_no: selectedUpdateLot?.lot_no,
            lot_alpha: selectedUpdateLot?.lot_alpha,
            contractItem: selectedUpdateLot?.contract_item?.id,
            paddle: values?.paddle,
            sale_value: values?.sale_value,
          },
          "paddle_no",
        ),
      )
        .then((res) => {
          if (res) {
            handleUpdateLotModalClose();
            if (formikValues) {
              formikValues.resetForm();
            }
            handleGetLotLists(lotsPayload);
          }
          setLotUpdateLoader(false);
          props.handleGetAuctionDetails(id);
        })
        .catch((error) => {
          handleUpdateLotModalClose();
          setLotUpdateLoader(false);
        });
    }
  };

  // get item description -> artist, title, description
  const getLotDescription = (row) => {
    let description = [];
    if (row?.contract_item?.author?.id) description.push(row?.contract_item?.author?.description);

    if (i18next?.resolvedLanguage === "pt") {
      if (row?.contract_item?.title_pt) description.push(row?.contract_item?.title_pt);
      if (row?.contract_item?.description_pt) description.push(row?.contract_item?.description_pt);
    } else {
      if (row?.contract_item?.title_en) description.push(row?.contract_item?.title_en);
      if (row?.contract_item?.description_en) description.push(row?.contract_item?.description_en);
    }
    return description?.length > 0 ? description.join(", ") : "-";
  };

  // export catalogues shipping
  const handleExportCataloguesShipping = () => {
    setExportBtnLoader(true);
    dispatch(exportCataloguesShipping(id)).then((res) => {
      setExportBtnLoader(false);
      if (res?.data?.data?.length > 0) {
        exportExcelWithTitleRow(
          generateCataloguesShippingExportData(res?.data?.data),
          `${props?.t("common.cataloguesShippingList")}-${props.t("common.auction")}(${auctionData?.auction_no})`,
          [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
          `Envio de catálogo – Leilão ${auctionData?.auction_no}`,
        );
      }
    });
  };

  const handleSelectAll = (event) => {
    const filterWithoutAuction = omit(lotsPayload.filter, "auction");
    const hasFilterOrSearchApplied = !!Object.keys(filterWithoutAuction)?.length || !!lotsPayload?.search?.length;
    const isAllSelected = !!event.target.checked;

    if (!isAllSelected) {
      if (hasFilterOrSearchApplied) {
        setSelectedRows((prevState) => [...prevState?.filter((item) => !allLotIds?.includes(item))]);
      } else {
        setSelectedRows([]);
      }
    } else {
      if (hasFilterOrSearchApplied) {
        setSelectedRows((prevState) => [...new Set([...prevState, ...allLotIds])]);
      } else {
        setSelectedRows(allLotIds);
      }
    }
    setExclude([]);
    setIsAllSelected(isAllSelected);
  };

  const columns = [
    {
      name: (
        <Label
          onClick={(e) => e.stopPropagation()}
          className="py-4 d-flex flex-row justify-content-center table-check-box"
        >
          <Input
            disabled={!lotsData?.length}
            type="checkbox"
            checked={isAllSelected}
            onChange={(event) => {
              handleSelectAll(event);
            }}
          />
        </Label>
      ),
      selector: (row) => (
        <Label onClick={(e) => e.stopPropagation()} className="table-check-box">
          <Input
            disabled={!lotsData?.length}
            type="checkbox"
            checked={Boolean(selectedRows?.find((item) => item === row?.id))}
            onChange={(event) => {
              const { checked } = event.target;
              if (checked) {
                setSelectedRows((prevState) => [...prevState, row?.id]);
              } else {
                setSelectedRows((prevState) => prevState?.filter((item) => item !== row?.id));
                setExclude((prevExclude) => [...prevExclude, row?.id]);
              }
            }}
          />
        </Label>
      ),
      width: "80px",
      center: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.lot")} ${props.t("common.no_")}`}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="search_lot_no__icontains"
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => (
        <>
          <Label
            onClick={() => {
              setPopoverOpen(true);
              setSelectedId(row);
            }}
            id={`popover-${row?.id}`}
            type="button"
          >
            <CustomTagChip
              title={row?.lot_no ? row?.lot_no + row?.lot_alpha : ""}
              bgColor={"#F6F6F6"}
              textColor={generateContrastColor("#F6F6F6")}
            />
          </Label>
        </>
      ),
      width: "135px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.photo")}`}</div>
        </div>
      ),
      selector: (row) => (
        <CustomLinkWrapper
          link={row?.contract_item?.default_photo}
          title={<CustomColumnPhoto sourceUrl={row?.contract_item?.default_photo} />}
        />
      ),
      width: "80px",
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={props.t("common.description")}
            sortable
            sortableKey={getColumnDetail("contract_item__description_en", "contract_item__description_pt")}
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey={getColumnDetail("custom_title_en__icontains", "custom_title_pt__icontains")}
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => getLotDescription(row),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.category")}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...CATEGORIES_OPTIONS]}
            selectedOptions={selectedCategory}
            handleOnSelection={(values) => {
              setSelectedCategory(values);
              handleDropdownFilter(values, "contract_item__categories__id__in", setSelectedCategory);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getConcatedStringFromArray(
          row?.contract_item?.categories,
          getColumnDetail("category_name_en", "category_name_pt"),
        ) || "-",
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            {`${props.t("contracts.contracts.contract")} ${props.t("common.no_")}`}
          </div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="contract_item__contract_item_no__icontains"
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.contract_item?.contract_item_no ? (
          <CustomTextLink
            text={`${row?.contract_item?.contract_item_no}`}
            handleClick={() => navigateToContractItemPage(row)}
          />
        ) : (
          "-"
        ),
      width: "135px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("auctions.lots.seller")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="seller_name__icontains"
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.contract_item?.contract?.customer?.id ? (
          <CustomTextLink
            text={getOnlyCustomerName(row?.contract_item?.contract?.customer)}
            handleClick={() => navigateToCustomerEditPage(row)}
          />
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("auctions.paddles.paddle")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="paddle__paddle_no__icontains"
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) => {
        const disableEditPaddle =
          row?.contract_item?.lots_tag.some((tag) => tag?.tag_label === "Sold") &&
          row?.contract_item?.lots_tag.some((tag) => tag?.tag_label === "Invoiced");

        return (
          <CustomTextLink
            text={row?.paddle?.id ? `${row?.paddle?.paddle_no}` : "-"}
            handleClick={disableEditPaddle ? null : () => handleUpdateLotModalOpen(row)}
            disabled={disableEditPaddle}
          />
        );
      },
      width: "135px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("auctions.lots.buyer")}</div>
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="buyer_name__icontains"
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),
      selector: (row) =>
        row?.paddle?.id && row?.paddle?.customer?.id ? getOnlyCustomerName(row?.paddle?.customer) : "-",
      width: "180px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <CustomColumnHeader
            columnTitle={`${props.t("common.sale")} ${props.t("common.value")}`}
            sortable
            sortableKey="sale_value"
            orderBy={orderBy}
            sortingOrder={sortingOrder}
            setOrderBy={setOrderBy}
            setSortingOrder={setSortingOrder}
          />
          <CustomSearchInput
            columnWise={true}
            columnSearchKey="sale_value__icontains"
            className="column-search-input"
            payload={lotsPayload}
            setPayload={setLotsPayload}
          />
        </div>
      ),

      selector: (row) => {
        const disableEditPaddle =
          row?.contract_item?.lots_tag.some((tag) => tag?.tag_label === "Sold") &&
          row?.contract_item?.lots_tag.some((tag) => tag?.tag_label === "Invoiced");

        return (
          <CustomTextLink
            text={row?.sale_value ? ` ${formatAmount(row?.sale_value)} €` : "-"}
            handleClick={disableEditPaddle ? null : () => handleUpdateLotModalOpen(row)}
            disabled={disableEditPaddle}
          />
        );
      },
      width: "135px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{`${props.t("common.tags_capital")}`}</div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...auctionLotTagOptions]}
            selectedOptions={selectedTags}
            handleOnSelection={(values) => {
              setSelectedTags(values);
              handleDropdownFilter(values, "contract_item__lots_tag__in", setSelectedTags);
            }}
          />
        </div>
      ),
      selector: (row) =>
        row?.contract_item?.lots_tag?.length > 0 ? (
          <div className="d-flex flex-column gap-1 m-2 tag-status">
            {row?.contract_item?.lots_tag?.map((option) => (
              <CustomTagChip
                title={getColumnDetail(option?.tag_label, option?.tag_label_pt)}
                bgColor={option?.tag_color}
                textColor={generateContrastColor(option?.tag_color || "#000")}
              />
            ))}
          </div>
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">
            {`${props.t("common.tags_capital")} ${props.t("common.bureaucracy")}`}
          </div>
          <CustomMultiSelect
            options={[SELECT_ALL_OPTION, ...TAG_BUREAUCRACY_OPTIONS]}
            selectedOptions={selectedTagsBureaucracy}
            handleOnSelection={(values) => {
              setSelectedTagsBureaucracy(values);
              handleDropdownFilter(values, "status", setSelectedTagsBureaucracy);
            }}
          />
        </div>
      ),
      selector: (row) =>
        getListOfTagsBureaucracy(row)?.length > 0 ? (
          <div className="d-flex flex-column gap-1 m-2 tag-status w-auto">
            {getListOfTagsBureaucracy(row)?.map((option) => (
              <CustomTagChip
                title={option?.label}
                bgColor={option?.color}
                textColor={generateContrastColor(option?.color || "#000")}
              />
            ))}
          </div>
        ) : (
          "-"
        ),
      width: "200px",
      wrap: true,
    },
    {
      name: (
        <div className="table-actions font-weight-bold fs-14 table-column-container py-4">
          <div className="table-column-title mb-2">{props.t("common.action")}</div>
        </div>
      ),
      selector: (row) => (
        <div className="table-actions">
          <button
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-primary rounded-circle light-dark-mode shadow-none"
            onClick={() => {
              handleDeleteModalOpen(row);
            }}
          >
            <CustomTooltipWrapper target={`tooltip-${row?.id}-delete`} tooltipBody={props.t("common.delete")} />
            <i className=" ri-delete-bin-fill fs-18" id={`tooltip-${row?.id}-delete`} />
          </button>
        </div>
      ),
      width: "100px",
      center: true,
    },
  ];

  return (
    <>
      <div ref={popoverRef} className="d-flex flex-row justify-content-end 123456 gap-2 mb-2 tab-inner-section-header">
        {(isAllSelected && totalLots > 0) || selectedRows.length ? (
          <Button
            color="info"
            onClick={() => {
              setSelectedRows([]);
              setIsAllSelected(false);
            }}
          >
            {props.t("common.deselect")}
            <Badge color="dark" className="ms-1">
              {selectedRows.length || totalLots}
            </Badge>
          </Button>
        ) : null}
        <CustomAddButton
          color={"success"}
          btnTitle={`${props.t("common.addTitle", {
            module: `${props.t("common.lot")}`,
          })}`}
          disabled={!props?.id}
          onClick={handleAddModalOpen}
        />

        <CustomButton
          color={"primary"}
          btnTitle={props.t("common.tags_capital")}
          disabled={!(isAllSelected && totalLots > 0) && selectedRows.length === 0}
          onClick={() => {
            handleBulkUpdateModalOpen();
          }}
        />
        {/* import lots / lots translation button */}
        <CustomDropdownButton
          btnTitle={props.t("common.import")}
          isLoading={exportBtnLoader}
          options={[
            {
              label: `${props.t("common.lots")}`,
              onClick: () => handleImportLotsModalOpen("lots"),
            },
            {
              label: `${props.t("common.translatedLots")}`,
              onClick: () => handleImportLotsModalOpen("translated_lots"),
            },
          ]}
        />

        <CustomDropdownButton
          disabled={!(isAllSelected && totalLots > 0) && selectedRows.length === 0}
          btnTitle={props.t("common.export_communications")}
          isLoading={exportBtnLoader}
          options={[
            {
              label: `${props.t("common.saleroom")}`,
              onClick: () => {
                handleExportExcel("saleroom", false, false, false);
              },
            },
            {
              label: `${props.t("common.invaluable")}`,
              onClick: () => {
                handleExportExcel("invaluable", false, false, false);
              },
            },
            {
              label: `${props.t("common.artprice")}`,
              onClick: () => {
                handleExportExcel("artprice", false, false, false);
              },
            },
            {
              label: `${props.t("common.wordpress")}`,
              onClick: () => {
                handleExportExcel("wordpress");
              },
            },
            {
              label: `${props.t("common.catalogue")}`,
              onClick: () => {
                handleExportExcel("catalogue", false, false, false);
              },
            },
            {
              label: `${props.t("common.exportCataloguesShipping")}`,
              onClick: () => {
                handleExportCataloguesShipping();
              },
              disabled: !totalLots,
            },
          ]}
        />

        <CustomDropdownButton
          disabled={!(isAllSelected && totalLots > 0) && selectedRows.length === 0}
          btnTitle={props.t("common.export")}
          isLoading={exportBtnLoader}
          options={[
            {
              label: `${props.t("common.generalExcel")}`,
              onClick: () => {
                handleExportExcel("generalExcel", false, false, false);
              },
            },
            {
              label: `${props.t("common.generalPJMap")}`,
              onClick: () => {
                handleExportExcel("generalPJMap", true, false, false);
              },
            },
            {
              label: `${props.t("common.jewelryPJMap")}`,
              onClick: () => {
                handleExportExcel("jewelryPJMap", false, true, false);
              },
            },
            {
              label: `${props.t("common.ASAEMap")}`,
              onClick: () => {
                handleExportExcel("ASAEMap", false, false, true);
              },
            },
            {
              label: `${props.t("common.exportTranslationTemplate")}`,
              onClick: () => {
                handleExportExcel("template", false, false, false);
              },
              disabled: !(isAllSelected && totalLots > 0) && selectedRows.length === 0,
            },
          ]}
        />

        <CustomButton
          isLoading={bulkDeleteLoader}
          color={"danger"}
          btnTitle={props.t("common.delete")}
          disabled={!(isAllSelected && totalLots > 0) && selectedRows.length === 0}
          onClick={() => {
            handleBulkDeleteModalOpen();
          }}
        />
      </div>
      {tableLoader ? <Loader /> : null}
      {props?.id ? (
        <Card className="subcard-table-container table-light">
          <DataTable
            fixedHeader
            className="data-table"
            persistTableHead
            columns={columns}
            data={[...lotsData]}
            pagination
            paginationServer
            paginationTotalRows={totalLots}
            paginationDefaultPage={lotsPayload?.page}
            paginationPerPage={lotsPayload?.limit}
            noDataComponent={<NoRecords />}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: props.t("common.rowsPerPage"),
              rangeSeparatorText: props.t("common.rangeSeparator"),
            }}
            pointerOnHover
            // onRowClicked={(row) => handleUpdateLotModalOpen(row)}
          />
        </Card>
      ) : null}
      {/* to add lots */}
      <AddLotModal
        isModalOpen={isAddModalOpen}
        onCancel={handleAddModalClose}
        onSave={handleSaveLots}
        loader={lotSaveLoader}
      />

      {/* bulk delete modal for multiple items */}
      <DeleteModal
        isModalOpen={isBulkDeleteModalOpen}
        onCloseBtnHandler={handleBulkDeleteModalClose}
        onConfirmBtnHandler={handleBulkDelete}
        title={`${props.t("common.delete")} ${selectedRows.length || totalLots} ${props.t("common.lot")} ${props.t(
          "common.items",
        )}`}
        body={props.t(
          selectedRows.length === 1 ? "confirmation.deleteMessage" : "confirmation.multipleItemDeleteMessage",
          {
            module: `${props.t(selectedRows.length === 1 ? "common.lot" : "common.lots")}`,
          },
        )}
        actionBtnTitle={props.t("common.delete")}
        loader={bulkDeleteLoader}
      />

      {/* delete modal for the lots */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCloseBtnHandler={handleDeleteModalClose}
        onConfirmBtnHandler={handleDeleteItem}
        title={`${props.t("common.delete")} ${props.t("common.lot")}`}
        body={props.t("confirmation.deleteMessage", {
          module: props.t("common.lot"),
        })}
        actionBtnTitle={props.t("common.delete")}
        loader={deleteLoader}
      />
      <UpdateLot
        isModalOpen={isUpdateModalOpen}
        lot={selectedUpdateLot}
        onCancel={handleUpdateLotModalClose}
        onSave={handleUpdateLot}
        loader={lotUpdateLoader}
        onClear={() => {
          handleUpdateLot({ sale_value: null, paddle: null });
        }}
      />
      <BulkUpdateLot
        isModalOpen={isBulkUpdateModalOpen}
        onCancel={handleBulkUpdateModalClose}
        onSave={handleBulkUpdateLots}
        loader={bulkLotUpdateLoader}
      />
      {/* import excel modal  */}
      <ImportExcelModal
        isModalOpen={isImportLotsModalOpen}
        onCancel={handleImportLotsModalClose}
        onSave={handleImportExcel}
        loader={importLotsLoader}
      />
      {/* lot no popover */}
      {popoverOpen ? (
        <LotPopover
          key={`popover-${selectedId?.id}`}
          isOpen={popoverOpen}
          target={`popover-${selectedId?.id}`}
          lotNo={selectedId?.lot_no}
          lotSuffix={selectedId?.lot_alpha}
          onCancel={() => {
            setPopoverOpen(false);
            setSelectedId(null);
          }}
          onSave={handleLotNoSave}
          onClear={() => {
            handleLotNoSave({
              delete_lot_no: true,
              lot_no: null,
              lot_alpha: null,
            });
          }}
          saveLoader={cellUpdateLoader}
        />
      ) : null}
    </>
  );
};

export default withTranslation()(Lots);
